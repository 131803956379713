import React, { useEffect, useState } from 'react';
import axios from 'axios';

function footer() {
  const [loaded, setLoaded] = useState(false);
  const [privacy, setPrivacy] = useState();
  const [termsOfUse, setTermsOfUse] = useState();
  const [conditions, setConditions] = useState();

  useEffect(() => {
    axios
      .post('/etqdocs', {
        id: ['00001LEGAL', '00002LEGAL', '00003LEGAL'],
        headers: {
          'Content-Type': ' text/html',
        },
      })
      .then((response) => {
        //console.log(response.data)
        //setDocs(response.data)
        response.data.forEach((elem) => {
          elem.title.includes('Use') ? (
            setTermsOfUse(elem)
          ) : elem.title.includes('Privacy') ? (
            setPrivacy(elem)
          ) : elem.title.includes('Conditions') ? (
            setConditions(elem)
          ) : (
            <></>
          );
        });
        setLoaded(true);
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  return (
    <footer className='container-fluid pt-2 pb-2 bg-light slate' style={{ height: '100%' }}>
      <hr />
      {loaded ? (
        <div className='container-fluid'>
          <div className='row text-center d-flex flex-column flex-sm-row align-items-center'>
            <div className='col-12 col-sm-4'>
              <p>&copy; Copyright {new Date().getFullYear()} by IMMY. All Rights Reserved.</p>
            </div>
            <div className='col-12 col-sm-4'>
              <a
                target='_blank'
                rel='noreferrer'
                href={`/downloadDoc?id=${privacy.id}&name=${privacy.title}`}
              >
                Privacy Policy
              </a>{' '}
              &bull;
              <a
                target='_blank'
                rel='noreferrer'
                href={`/downloadDoc?id=${termsOfUse.id}&name=${termsOfUse.title}`}
              >
                Terms of Use
              </a>{' '}
              &bull;
              <a
                target='_blank'
                rel='noreferrer'
                href={`/downloadDoc?id=${conditions.id}&name=${conditions.title}`}
              >
                Standard Terms and Conditions
              </a>
              <p className=''>2701 Corporate Centre Dr, Norman, OK 73069</p>
            </div>
            <div className='col-12 col-sm-4'>
              <a href='https://twitter.com/immydiagnostics' rel='noreferrer' target='_blank'>
                <img
                  className='media-object'
                  src='/images/twitter.svg'
                  alt='twitter'
                  height='24px'
                  width='24px'
                />
              </a>
              &bull;
              <a
                target='_blank'
                rel='noreferrer'
                href='https://www.linkedin.com/company/immuno-mycologics-inc./about/'
              >
                <img
                  className='media-object'
                  alt='linkedin'
                  src='/images/linkedin.svg'
                  height='24px'
                  width='24px'
                />
              </a>
              &bull;
              <a
                target='_blank'
                rel='noreferrer'
                href='https://www.instagram.com/immy.diagnostics/'
              >
                <img
                  className='media-object'
                  alt='instagram'
                  src='/images/instagram.svg'
                  height='24px'
                  width='24px'
                />
              </a>
              <p className=''>(405) 360-4669 8:30am - 5:00pm CST</p>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </footer>
  );
}

export default footer;
