import React, { useRef, useEffect, useState } from "react";
//import "../../public/";
const AutoComplete = (props) => {
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const [address, setAddress] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [country, setCountry] = useState('');
  const autocomplete = useRef(null);
  const options = {
    //componentRestrictions: {  },
    fields: ["address_components", "geometry", "icon", "name"],
    types: []
  };
  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );
    initAutocomplete()
  }, []);

  const initAutocomplete = () => {
    const input = document.getElementById('autocomplete');
    autocomplete.current = new window.google.maps.places.Autocomplete(input);
    autocomplete.current.addListener('place_changed', handlePlaceSelect);
  };

  const handlePlaceSelect = () => {
    const place = autocomplete.current.getPlace();
    const addressComponents = place.address_components;

    let streetAddress = '';
    let city = '';
    let state = '';
    let postalCode = '';
    let country = '';

    for (const component of addressComponents) {
      const componentType = component.types[0];

      switch (componentType) {
        case 'street_number':
          streetAddress = component.long_name;
          break;
        case 'route':
          streetAddress += ' ' + component.long_name;
          break;
        case 'locality':
          city = component.long_name;
          break;
        case 'administrative_area_level_1':
          state = component.long_name;
          break;
        case 'postal_code':
          postalCode = component.long_name;
          break;
        case 'country':
          country = component.long_name;
          break;
        default:
          break;
      }
    }

    setStreetAddress(streetAddress);
    setCity(city);
    setState(state);
    setPostalCode(postalCode);
    setCountry(country);

    props.updateFormData(streetAddress, city, state, postalCode, country)

    // Process and use the address components as needed
    //console.log(addressComponents);
  };

  const handleChange = (event) => {
    setAddress(event.target.value);
  };

  return (
    <div className="form-floating mb-3">
      <input type="text" className="form-control" id="autocomplete" placeholder="Norman, Oklahoma" ref={inputRef} onChange={(e) => {handleChange(e)}}/>
      <label htmlFor="floatingInput">*Facility Address</label>
    </div>
  );
};
export default AutoComplete;