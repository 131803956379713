import React from 'react';
import '../App.css';

function CandID() {
  return (
    <div className='container-fluid pt-3'>
      <div className="container  verlag-bold pt-1">
        <h1 style={{ color: '#8a8a8d' }} className="text-center"><i style={{ color: '#5eaed5' }}>Cand </i>ID</h1>
        <p className="verlag-light">
          <p className="text-center">
            Multiplex PCR kit for the detection of clinically relevant <i>Candida</i> species <br />
          </p>
        </p> <br/>
        <div className="text-center verlag-black">
          <button
            type="button"
            style={{width: '30%'}}
            className="btn btn-outline-info btn-block"
            onClick={() => window.location.href = '/contact'}
          >
            CONTACT US
          </button>
        </div>
        <div className="container-olm">
          <p>
            Product Code: OLM2007
          </p>
          <p className="verlag-light">
            <b>Specimens:</b> Serum and Plasma <br />
            50 reactions per kit
          </p>
          <p className="verlag-light">
            <b>Targets</b> <br />
            • <i>C. albicans</i> <br />
            • <i>C. glabrata</i> <br />
            • <i>C. parapsilosis</i> <br />
            • <i>C. tropicalis</i> <br />
            • <i>C. dubliniensis</i> <br />
            • <i>C. krusei</i> <br />
            • Internal Extraction Control (IEC) <br /><br />
            <b>Features and benefits</b> <br />
            • Culture-independent diagnostics <br />
            • Detection of the six main causative species associated with invasive candidiasis (IC) <br />
            • Differentiation of those resistant to first-line treatment: <i>C. albicans</i>, <i>C. glabrata</i>, <i>C.
            parapsilosis</i>, <i>C.
            tropicalis</i>, <i>C. dubliniensis</i> and <i>C. krusei</i>. <br />
            • Results within 45 minutes of nucleic acid extraction from serum/plasma <br />
            • ‘Ready to use’ reagents – no resuspension/dilution steps required <br />
            • Compatible with existing laboratory equipment <br />
            • Enabling quick laboratory reporting to support clinical decision-making when time is of the
            essence <br /><br />
            <b>Kit Contents</b> <br />
            • Primer/Probe Mix (CandID) <br />
            • Primer/Probe Mix (CandID PLUS) <br />
            • qPCR Master Mix <br />
            • RNase/DNase-free water <br />
            • Positive Controls (CandID/CandID PLUS) <br />
            • Internal Extraction Control (IEC) <br /><br />
            <b>Performance Characteristics </b> <br />
            • Sensitive to  &lt;1 <i>Candida</i> genome copy <br />
            • Proven efficacy in the diagnosis of <i>Candida</i> BSI <br /><br />

            <b>Background</b> <br />
            Over the last two decades, <i>Candida</i> has emerged as one of the most important pathogens causing
            bloodstream
            infections in both adults and children worldwide, representing up to 9% of all nosocomial bloodstream
            infections. <i>Candida</i> bloodstream infection (BSI), also called candidemia, is the most common form of
            Invasive
            Candidiasis (IC). The spectrum of disease of IC ranges from minimally symptomatic candidemia to fulminant
            sepsis with associated mortality exceeding 70%. In addition, an invasive <i>Candida</i> infection increases
            the
            length of hospital stays by as much as 30 days, representing a burden not only for patients but also for the
            healthcare system. <br /><br />

            Risk factors for the development of <i>Candida</i> BSI include hematologic or solid-organ malignancy,
            previous
            administration of antimicrobial agents, corticosteroids or chemotherapeutic agents, neutropenia, prior
            fungal
            colonisation, extensive intra-abdominal surgery or burns, central venous catheter, mechanical ventilation or
            admission to an intensive care unit (ICU). <br /><br />

            As a single species, <i>C. albicans</i> accounts for close to 50% of overall
            invasive <i>Candida</i> infection.
            However,
            there has been a proportionate increase in the isolation of nonalbicans species of <i>Candida</i>, some of
            which
            are
            resistant to commonly used antifungal drugs. Therefore, an early diagnosis is of the utmost importance to
            initiate the appropriate treatment. However, despite the recognition of risk factors, the diagnosis and
            consequently treatment of invasive candidiasis is frequently delayed. The current gold standard for
            diagnosing
            IC is culture of blood and specimens from deep-seated sites of infection. This method has a sensitivity of
            approximately 50% and takes 24 to 48 h to become positive. It has been shown that the administration of
            appropriate treatment more than 12 h after the first positive blood sample for culture is drawn, is
            associated
            with higher hospital mortality. In this context, non-culture-based methods for the identification of <i>Candida</i>,
            such as DNA detection by PCR, represent a promising approach to allow rapid diagnosis and initiation of
            species-oriented therapy as soon as possible after the onset of sepsis. <br />

          </p> <br /><br />
          <br /><br />
        </div>
      </div>
    </div>
  );
}

export default CandID;
