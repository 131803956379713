import React from 'react';

function Cry_Relevancy() {
  return (
    <div>
      <div className='col-12 text-center pb-4'>
        <h1 className='verlag-bold'>
          Clinical Relevancy
          <sup>
            <small>2</small>
          </sup>
        </h1>
      </div>
      <div className='col-12 col-sm-12 offset-md-2 col-md-8 offset-xl-2 col-xl-8 d-flex justify-content-center'>
        <div className='d-inline-block'>
          <table
            className='table table-striped table-responsive table-bordered table-info pb-0 mb-0 my-auto verlag-black text-center align-middle w-auto border-top-0 border-left-0'
            style={{ borderLeftColor: 'white !important', borderTopColor: 'white !important' }}
          >
            <thead>
              <tr className='border-top-0'>
                <th
                  scope='col'
                  className='h2 align-middle table_header'
                  style={{
                    backgroundColor: 'white !important',
                    borderTopColor: 'white !important',
                    borderLeftColor: 'white !important',
                  }}
                >
                  Competitor EIA
                </th>
                <th scope='col' className='h5 align-middle'>
                  Positive % Agreement
                </th>
                <th scope='col' className='h5 align-middle'>
                  Negative % Agreement{' '}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope='row' className='h5 align-middle'>
                  Serum
                </th>
                <td className='h4 align-middle'>98.5%</td>
                <td className='h4 align-middle'>97.7%</td>
              </tr>
              <tr>
                <th scope='row' className='h5 align-middle'>
                  CSF
                </th>
                <td className='h4 align-middle'>93.5%</td>
                <td className='h4 align-middle'>99.2%</td>
              </tr>
            </tbody>
          </table>
          <small className='text-left'>2.IMMY CRY101 IFU</small>
        </div>
      </div>
    </div>
  );
}

export default Cry_Relevancy;
