import React, { useState, useEffect } from 'react';
import DocumentTable from './DocumentTable';
import axios from 'axios';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import * as DOMPurify from 'dompurify';

function latex(props) {
  let [docs, setDocs] = useState();

  useEffect(() => {
   axios
      .post('/etqdocs', {
        id: props.etq,
        headers: {
          'Content-Type': ' text/html',
        },
      })
      .then((response) => {
        //console.log(response.data)
        setDocs(response.data);
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  return (
    <div>
      {/* <style>
    .container p{
        font-size: 1.3rem;
    }
        </style> */}
      {/* <!-- Page Content --> */}
      <div className='container-fluid  pt-3'>
        <div className='container'>
          {/* <!-- Portfolio Item Heading --> */}
          <div className='row'>
            {props.title_override ? (
              props.prod_title
            ) : (
              <div className=' my-auto  col-12 offset-sm-2 col-sm-10 offset-md-1 col-md-10 offset-lg-3 col-lg-8'>
                <h1
                  className='verlag-compressed-bold  my-0'
                  style={{ color: 'grey', marginBottom: '-5px !important' }}
                >
                  {' '}
                  <u>{props.title_1}</u>
                </h1>
                <h1
                  className='verlag-black my-0 display-4 text-capitalize font-italic '
                  style={{ color: props.title_2_color, marginTop: '-5px !important' }}
                >
                  {props.title_2}
                </h1>
                <h1
                  className='verlag-light_italic display-4 my-0 text-responsive-small '
                  style={{ marginTop: '-10px !important', color: 'grey' }}
                >
                  {props.title_3}
                </h1>
              </div>
            )}
          </div>

          <hr />

          <div className='row'>
            <div className='col-sm-12 col-md-12  col-lg-8'>
              <img className='img-fluid' src={props.product_image} alt='' />
            </div>

            <div className='col-sm-12 col-md-12 offset-lg-0 col-lg-4 verlag-light'>
              <p>
                IMMY has developed Latex Agglutination (LA) tests for a variety of diseases. These
                antibody or antigen detection systems are semi-quantitative tests that detect
                agglutinating antibodies in the serum of patients with the respective fungal
                diseases. The LA tests can aid in the diagnosis of the disease.
              </p>
              <div className='text-center verlag-black mt-auto'>
                <button
                  type='button'
                  className='btn btn-outline-info btn-block'
                  onClick={() => window.location.href ='/contact'}
                >
                  CONTACT US
                </button>
              </div>
              {/* <!--
                        <h6 className="verlag-black text-right pt-2 pt-sm-2 pt-lg-4 pt-md-4 pt-xl-4">{props.CE_marked ? <img className="img-fluid" style="max-width: 35px" src="/images/ce-mark.png"/> : CE_desc}</h6>
        --> */}
            </div>
            <div className='col-12 pt-3'>
              <p>
                The <i>LA-Coccidioides</i> (REF CL1001) and <i>LA-Histoplasma</i> (REF HL1001) antibody detection
                systems are semi-quantitative tests. They detect agglutinating antibodies against
                <i> Coccidioides immitis</i> (posadasii) or <i>Histoplasma capsulatum</i> in patient serum to aid
                in the diagnosis of each respective disease.
              </p>
              <h6 className='verlag-black '>
                FDA :{' '}
                <img className='img-fluid' style={{ maxWidth: '35px' }} src='/images/ce-mark.png' />
                : Serum
              </h6>
              <p className='pt-2'>
                <i>The LA-Cryptococcus</i> (CR1003, CR1004) antigen detection systems are qualitative or
                semi-quantitative tests that detect antigens against <i>Cryptococcus neoformans</i> in
                serum and <i>cerebrospinal fluid</i> (CSF) as an aid in the diagnosis of Cryptococcosis.
              </p>
              <h6 className='verlag-black  '>
                FDA :{' '}
                <img className='img-fluid' style={{ maxWidth: '35px' }} src='/images/ce-mark.png' />
                : Serum, CSF
              </h6>
            </div>
          </div>
        </div>
      </div>

      <hr style={{ width: '75%' }} />

      <div className='container mt-5 mb-5'>
        <div className='row'>
          <div className='col-12  col-sm-12 offset-md-1 col-md-10 offset-lg-2 col-lg-8'>
            <Tabs className='nav nav-tabs verlag-black border-bottom' defaultActiveKey='docs'>
              <Tab className='border' eventKey='docs' title='DOCUMENTS'>
                <div className='tab-pane' id='DOCUMENTS' style={{ minHeight: '250px' }}>
                  {docs ? <DocumentTable documents={docs} /> : <></>}
                </div>
              </Tab>
              <Tab className='border' eventKey='ordering' title='ORDERING INFORMATION'>
                <div
                  className='tab-pane table-responsive'
                  id='ordering'
                  style={{ minHeight: '250px' }}
                >
                  <table className='table table-borderless' width='100%'>
                    <thead style={{ height: '0px' }}>
                      <tr style={{ border: 'none', width: '100%' }}>
                        <th
                          scope='col'
                          style={{ height: '0px', border: 'none', width: '33%' }}
                        ></th>
                        <th
                          scope='col'
                          style={{ height: '0px', border: 'none', width: '33%' }}
                        ></th>
                        <th
                          scope='col'
                          style={{ height: '0px', border: 'none', width: '33%' }}
                        ></th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.ordering.map((line) => {
                        return (
                          <tr key={line.name}>
                            <th
                              scope="row"
                              className="orderingTable"
                              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(line.name) }}
                            ></th>
                            <td
                              className="text-center orderingTable"
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(line.quantity),
                              }}
                            ></td>
                            <td
                              className="text-right orderingTable"
                              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(line.code) }}
                            ></td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
}

export default latex;
