import React from 'react';

function CragSQ_Relevancy() {
  return (
    <div>
      <div className='col-12 verlag-light pt-2 text-center'>
        <h1 className='verlag-bold'>4 Easy Steps</h1>
        <img className='col-12 my-2' src='/images/CrAgSQ_4steps.jpg' alt='4 Easy Steps' />
      </div>
      <hr width='100%' />
      <div className='col-12 text-center pb-3'>
        <h1 className='verlag-bold'>
          Clinical Relevancy
          <sup>
            <small>2,3,4,5</small>
          </sup>
        </h1>
      </div>
      <div className='col-12'>
        <table
          className='table table-striped table-bordered table-info pb-0 mb-0 verlag-black text-center align-middle border-top-0'
          style={{ borderLeftColor: 'white !important', borderTopColor: 'white !important' }}
        >
          <thead>
            <tr
              className='border-top-0'
              style={{
                backgroundColor: 'white !important',
                borderTopColor: 'white !important',
                borderLeftColor: 'white !important',
              }}
            >
              <th scope='col' className='h2 align-middle table_header'>
                STUDY{' '}
              </th>
              <th scope='col' className='h5 align-middle'>
                Sensitivity{' '}
              </th>
              <th scope='col' className='h5 align-middle'>
                Specificity
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope='row' className='h5 align-middle'>
                Jarvis et al.
              </th>
              <td className='h4 align-middle'>93%</td>
              <td className='h4 align-middle'>93.8%</td>
            </tr>
            <tr>
              <th scope='row' className='h5 align-middle'>
                Skipper et al.
              </th>
              <td className='h4 align-middle'>98%</td>
              <td className='h4 align-middle'>98%</td>
            </tr>
            <tr>
              <th scope='row' className='h5 align-middle'>
                Govender et al.
              </th>
              <td className='h4 align-middle'>98.3%</td>
              <td className='h4 align-middle'>100%</td>
            </tr>
            <tr>
              <th scope='row' className='h5 align-middle'>
                Tadeo et al.
              </th>
              <td className='h4 align-middle'>100%</td>
              <td className='h4 align-middle'>100%</td>
            </tr>
          </tbody>
        </table>
        <p className='verlag-light pt-2'>
          The CrAgSQ demonstrates excellent diagnostic performance, maintaining both the sensitivity
          and specificity of the CrAg LFA, and counters false-negative prozone effects. The CrAgSQ
          assay reading is more complex but provides useful clinical information about disease
          burden and probability of culture positivity in a single rapid diagnostic test.
          <sup>2</sup>
        </p>
        <p className='verlag-light pt-2'>
          Increasing CrAgSQ scores were strongly associated with 10-week mortality. The IMMY CrAgSQ
          test had high sensitivity and specificity compared to the results for the IMMY CrAg LFA
          and provided CrAg scores that were associated with both conventional CrAg titers and
          clinical outcomes.<sup>3</sup>
        </p>
        <p className='verlag-light pt-2'>
          The new semiquantitative CrAg LFA allows rapid estimation of titer levels in
          easy-to-perform platforms. The CrAgSQ test demonstrated better qualitative sensitivity and
          specificity than the CryptoPS compared to the reference standard.<sup>4</sup>
        </p>
        <p className='verlag-light pt-2'>
          The SQ assay was simple and rapid to perform with identical testing steps to a
          single-strip qualitative LFA and an equivalent accuracy for CrAg detection in stored
          plasma. Patients with an SQ score of 3 or 4 also had a 2.2-fold increased adjusted hazards
          of 6-month mortality.<sup>5</sup>
        </p>
        <br />
        <small className='text-left pt-2'>
          2) Tadeo KK, Nimwesiga A, Kwizera R, et al. Evaluation of the Diagnostic Performance of a
          Semi-Quantitative Cryptococcal Antigen Point-of-Care Assay among HIV-infected Persons with
          Cryptococcal Meningitis [published online ahead of print, 2021 Jun 2]. J Clin Microbiol.
          2021;59(8):e008602
        </small>
        <br />
        <small className='text-left pt-2'>
          3) Jarvis JN, Tenforde MW, Lechiile K, et al. Evaluation of a Novel Semiquantitative
          Cryptococcal Antigen Lateral Flow Assay in Patients with Advanced HIV Disease. J Clin
          Microbiol. 2020;58(9):e00441-20.
        </small>
        <br />
        <small className='text-left pt-2'>
          4) Skipper C, Tadeo K, Martyn E, et al. Evaluation of Serum Cryptococcal Antigen Testing
          Using Two Novel Semiquantitative Lateral Flow Assays in Persons with Cryptococcal
          Antigenemia. J Clin Microbiol. 2020;58(4):e02046-19
        </small>
        <br />
        <small className='text-left pt-2'>
          5) Blasich NP, Wake RM, Rukasha I, Prince Y, Govender NP. Association of semi-quantitative
          cryptococcal antigen results in plasma with subclinical cryptococcal meningitis and
          mortality among patients with advanced HIV disease. Med Mycol. 2021;59(10):1041-1047
        </small>
      </div>
    </div>
  );
}

export default CragSQ_Relevancy;
