/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import DocumentTable from './DocumentTable';
import axios from 'axios';

function MycoDDR(props) {
  let [docs, setDocs] = useState();

  useEffect(() => {
    axios
    .post('/etqdocs', {
      id: props.etq,
      headers: {
        'Content-Type': ' text/html',
      },
    })
    .then((response) => {
      //console.log(response.data)
      setDocs(response.data);
    })
    .catch((e) => {
      console.error(e);
    });
  }, []);

  return (
    <div>
      <div className='container-fluid  pt-3'>
        <div className='container'>
          {/* <!-- Portfolio Item Heading --> */}
          <div className='row' style={{ color: '#01579B' }}>
            <div className=' col-12 offset-sm-2 col-sm-10 offset-md-1 col-md-10 offset-lg-4 col-lg-8 pt-3'>
              <h1 className='verlag-black my-0 display-4 font-italic myco'>
                Myco{' '}
                <sup className='verlag-light' style={{ textDecoration: 'overline underline' }}>
                  DDR
                </sup>
              </h1>
              <h1
                className='verlag-black my-0 display-3 pl-5 font-italic myco'
                style={{ marginTop: '-10px !important' }}
              >
                Trident
              </h1>
            </div>
          </div>

          <hr />

          {/* <!-- Portfolio Item Row --> */}
          <div className='row'>
            <div className='col-sm-12 col-md-12 col-lg-8'>
              <img className='img-fluid' src='/images/immy/myco_ddr4.jpg' alt='' />
            </div>

            <div className='col-sm-12 col-md-12 offset-lg-0 col-lg-4 verlag-light'>
              <h2 className=' verlag-light_italic my-0'>Redefine</h2>
              <h1
                className='verlag-black pt-0 text-center  font-italic myco'
                style={{ color: '#01579B' }}
              >
                AFB Processing
              </h1>
              <p>
                MycoDDR is a reliable processing system that facilitates the digestion and
                decontamination of patient specimens in a controlled process, which is essential for
                mycobacterium survival and recovery.
              </p>
              <p>
                {' '}
                This rigid pH control leads to faster culture results, improved smear quality, and
                decreases lab tech variability. It is time to redefine AFB processing, and it all
                starts with MycoDDR.
              </p>
              <div className='text-center verlag-black'>
                <button
                  type='button'
                  className='btn btn-outline-info btn-block'
                  onClick={() => window.location.href ='/contact'}
                >
                  CONTACT US
                </button>
              </div>
            </div>

            <div className='col-12  pt-3 text-center'>
              <h1 className='text-left font-italic verlag-black' style={{ color: '#01579B' }}>
                Benefits of Rigid pH Control:
              </h1>
              <ul
                className='text-left  h2'
                style={{ display: 'inline-block', verticalAlign: 'middle', fontWeight: '400' }}
              >
                <li className='p-1'>Smears are easier to interpret</li>
                <li className='p-1'>Obtain culture results 1 - 7 days faster</li>
                <li className='p-1'>Reduce the risk of false negatives</li>
              </ul>
            </div>
            <div className='col-12 pt-5'>
              <img className='img-fluid' src='/images/immy/procedure.png' alt='' />
            </div>
          </div>
        </div>
      </div>

      <hr style={{ width: '75%' }} />

      <div className='container-fluid  pt-3'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 text-center pb-4'>
              <h1 className='verlag-black display-4'>The Importance of Rigid pH Control</h1>
            </div>

            <div className='col-12'>
              <img className='img-fluid' src='/images/immy/ph_chart.png' alt='' />
            </div>
          </div>
        </div>
      </div>
      <div className='col-12 text-center verlag-bold'>
        <h1>Procedure</h1>
      </div>
      <div className='col-10  col-sm-10 offset-md-1 col-md-10 mx-auto text-center embed-responsive embed-responsive-16by9'>
        <iframe
          className='embed-responsive-item'
          src='https://www.youtube.com/embed/5uQO6Vl_UHs?start=4'
          allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
          allowfullscreen
        ></iframe>
      </div>
      <hr style={{ width: '75%' }} />

      <div className='container-fluid  mb-5'>
        <div className='row'>
          <div className='col-12  col-sm-12 offset-md-1 col-md-10 offset-lg-2 col-lg-8'>
            <Tabs className='nav nav-tabs verlag-black border-bottom' defaultActiveKey='options'>
              <Tab className='border' eventKey='options' title='PACKAGING OPTIONS'>
                <div className='tab-pane' id='OPTIONS' style={{ minHeight: '250px' }}>
                  <table
                    className='table table-responsive table-borderless blueText'
                    width='100%'
                    style={{ color: '#01579B !important' }}
                  >
                    <thead style={{ height: '0px' }} height='0%'>
                      <tr style={{ height: '0px !important', border: 'none' }}>
                        <th
                          scope='col'
                          width='15%'
                          style={{ height: '0px !important', border: 'none' }}
                          className='m-0 p-0'
                        ></th>
                        <th
                          scope='col'
                          width='30%'
                          style={{ height: '0px !important', border: 'none' }}
                        ></th>
                        <th
                          scope='col'
                          width='25%'
                          style={{ height: '0px !important', border: 'none' }}
                        ></th>
                        <th
                          scope='col'
                          width='25%'
                          style={{ height: '0px !important', border: 'none' }}
                        ></th>
                      </tr>
                    </thead>
                    <tbody style={{ color: '#01579B !important' }} className='blueText'>
                      <tr>
                        <th scope='row'>
                          NaOH/NALC
                          <br /> Reagent A
                        </th>
                        <td>
                          2.5% NaOH / NALC Reagent A - Individual Bulk
                          <br />
                          3.0% NaOH / NALC Reagent A - Individual Bulk
                        </td>

                        <td className='text-center'>
                          10 x 60 mL NaOH / 10 x 300 mg NALC
                          <br />
                          10 x 60 mL NaOH / 10 x 300 mg NALC
                        </td>
                        <td className='text-right'>
                          TBNN1010-2.5
                          <br />
                          TBNN1010-3.0
                        </td>
                      </tr>
                      <tr>
                        <th scope='row'>NALC</th>
                        <td>5 Pack</td>

                        <td className='text-center'>5 x 300 mg NALC</td>
                        <td className='text-right'>TBP300-5</td>
                      </tr>
                      <tr>
                        <th scope='row'>
                          Neutralization <br />
                          Buffer B
                        </th>
                        <td>
                          Individual Bulk
                          <br />
                          Large Bulk
                        </td>

                        <td className='text-center'>
                          60 x 30 mL
                          <br />8 x 500 mL
                        </td>
                        <td className='text-right'>
                          TBPN67-60
                          <br />
                          TBPN67-8
                        </td>
                      </tr>
                      <tr>
                        <th scope='row'>
                          Resuspension <br />
                          Buffer C
                        </th>
                        <td>Individual Bulk</td>

                        <td className='text-center'>60 x 3 mL</td>
                        <td className='text-right'>TBRB30-60</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Tab>
              <Tab className='border' eventKey='docs' title='DOCUMENTS'>
                <div className='tab-pane' id='DOCUMENTS' style={{ minHeight: '250px' }}>
                  {docs ? <DocumentTable documents={docs} /> : <></>}
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MycoDDR;
