/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5map from '@amcharts/amcharts5/map';
import am5geodata_worldLow from '@amcharts/amcharts5-geodata/worldLow';
import anime from 'animejs/lib/anime.es.js';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import axios from 'axios';

function Home() {
  const [countries, setCountries] = useState([]);

  function doAnimateBounce() {
    anime.timeline({ loop: false }).add({
      targets: [
        '.diamond1',
        '.diamond2',
        '.diamond3',
        '.diamond4',
        '.diamond5',
        '.diamond6',
        '.diamond7',
        '.diamond8',
        '.diamond9',
        '.diamond10',
        '.diamond11',
      ],
      opacity: 1,
      duration: 750,
      easing: 'linear',
      delay: anime.stagger(750),
    });
  }

  useEffect(() => {
    axios
      .get('/countries', {
        headers: {
          'Content-Encoding': 'gzip, compress, br',
        },
      })
      .then((response) => {
        //console.log(response.data.recordsets)
        setCountries(response.data.recordsets);
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  useEffect(() => {
    doAnimateBounce();
    let root = am5.Root.new('chartdiv');
    let chart = root.container.children.push(
      am5map.MapChart.new(root, {
        projection: am5map.geoOrthographic(),
        panX: 'rotateX',
        panY: 'rotateY',
      }),
    );
    let polygonSeries = chart.series.push(
      am5map.MapPolygonSeries.new(root, {
        geoJSON: am5geodata_worldLow,
      }),
    );
    polygonSeries.set('fill', am5.color(0x8c8c8c));
    polygonSeries.set('stroke', am5.color(0x000000));

    let graticuleSeries = chart.series.unshift(am5map.GraticuleSeries.new(root, {}));

    graticuleSeries.mapLines.template.setAll({
      stroke: am5.color(0x000000),
      strokeOpacity: 0.1,
    });
    let pointSeries = chart.series.push(
      am5map.MapPointSeries.new(root, {
        // ...
      }),
    );

    root.setThemes([am5themes_Animated.new(root)]);

    chart.animate({
      key: 'rotationX',
      from: 0,
      to: 360,
      duration: 30000,
      loops: Infinity,
    });

    chart.appear(1000, 100);

    //let norman = pointSeries.pushDataItem({ latitude: 35, longitude: -97, name: "Norman, OK"});
    // let argentina = pointSeries.pushDataItem({ latitude: -35, longitude: -63, name: "Argentina"});
    // let australia = pointSeries.pushDataItem({ latitude: -25, longitude: 134, name: "Australia"});
    // let austria = pointSeries.pushDataItem({ latitude: 47, longitude: 14, name: "Austria"});
    // let belgium = pointSeries.pushDataItem({ latitude: 50, longitude: 4, name: "Belgium"});
    // let benin = pointSeries.pushDataItem({ latitude: 9, longitude: 2, name: "Benin"});
    // let bolivia = pointSeries.pushDataItem({ latitude: -17, longitude: -64, name: "Bolivia"});
    // let botswana = pointSeries.pushDataItem({ latitude: -22, longitude: 24, name: "Botswana"});
    // let brazil = pointSeries.pushDataItem({ latitude: -9, longitude: -52, name: "Brazil"});
    // let cambodia = pointSeries.pushDataItem({ latitude: 12, longitude: 104, name: "Cambodia"});
    // let cameroon = pointSeries.pushDataItem({ latitude: 5, longitude: 11, name: "Cameroon"});
    // let canada = pointSeries.pushDataItem({ latitude: 58, longitude: -102, name: "Canada"});
    // let chile = pointSeries.pushDataItem({ latitude: -34, longitude: -71, name: "Chile"});
    // let china = pointSeries.pushDataItem({ latitude: 35, longitude: 104, name: "China"});
    // let colombia = pointSeries.pushDataItem({ latitude: 3.5, longitude: -72, name: "Colombia"});
    // let costaRica = pointSeries.pushDataItem({ latitude: 9.8, longitude: -83, name: "Costa Rica"});
    // let ecuador = pointSeries.pushDataItem({ latitude: -1.5, longitude: -78, name: "Ecuador"});
    // let ethiopia = pointSeries.pushDataItem({ latitude: 8, longitude: 39, name: "Ethiopia"});
    let countryData = [];
    if (countries[0]) {
      countries[0].forEach((country) => {
        //console.log("Adding Point")
        countryData.push({
          geometry: {
            coordinates: [Math.trunc(country.Longitude), Math.trunc(country.Latitude)],
            name: country['Ship To Country'],
            type: 'Point',
          },
        });
      });
      countryData.push({ geometry: { coordinates: [-97, 35], name: 'Norman, OK', type: 'Point' } });
    }
    //console.log(countryData, "DATA")
    pointSeries.data.setAll(countryData);

    pointSeries.bullets.push(function (root, series, dataItem) {
      if (dataItem.dataContext.geometry.name === 'Norman, OK') {
        let circle = am5.Picture.new(root, {
          // fill: am5.color(0xaf2538),
          width: 24,
          height: 24,
          centerX: am5.percent(50),
          centerY: am5.percent(50),
          src: '/images/immy_logo.png',
          tooltipText: '{geometry.name}',
        });

        circle.animate({
          key: 'opacity',
          to: 1,
          from: 0.2,
          duration: 3000,
          easing: am5.ease.yoyo(am5.ease.linear),
          loops: Infinity,
        });

        circle.animate({
          key: 'angle',
          to: 330,
          from: 30,
          duration: 3000,
          easing: am5.ease.inOut(am5.ease.elastic),
          loops: Infinity,
        });

        circle.events.on('click', function () {
          //console.log(ev.target.dataItem);
        });

        return am5.Bullet.new(root, {
          sprite: circle,
        });
      } else {
        let circle = am5.Circle.new(root, {
          radius: 3,
          fill: am5.color(0x00539b),
          tooltipText: '{geometry.name}',
        });

        circle.events.on('click', function () {
          //console.log(ev.target.dataItem);
        });

        return am5.Bullet.new(root, {
          sprite: circle,
        });
      }
    });

    return () => {
      root.dispose();
    };
  }, [countries]);

  return (
    <>
      <div className='container-fluid  pt-3'>
        <div className='row'>
          <div className='offset-1 col-10'>
            <svg
              version='1.1'
              id='Layer_1'
              xmlns='http://www.w3.org/2000/svg'
              // xlink="http://www.w3.org/1999/xlink"
              x='0px'
              y='0px'
              viewBox='0 0 1920 1080'
              style={{ enableBackground: 'new 0 0 1920 1080' }}
              //space="preserve"
            >
              <image
                id='background-master'
                style={{ overflow: 'visible' }}
                width='1606'
                height='1060'
                href='/images/stock_photo.png'
                transform='matrix(1 0 0 1 48.4852 11)'
              >
                {' '}
              </image>
              <radialGradient
                id='SVGID_1_'
                cx='690.0283'
                cy='539.54'
                r='778.0322'
                gradientTransform='matrix(1.0948 0 0 -1 114.947 1080)'
                gradientUnits='userSpaceOnUse'
              >
                <stop offset='0' style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}></stop>
                <stop offset='0.9995' style={{ stopColor: '#FFFFFF' }}></stop>
              </radialGradient>
              <rect x='-179.18' y='0.46' className='st0' width='2099.18' height='1080'></rect>
              <g id='Layer_3'>
                <g>
                  <linearGradient
                    id='SVGID_2_'
                    gradientUnits='userSpaceOnUse'
                    x1='1202.6917'
                    y1='546.5996'
                    x2='2024.5625'
                    y2='546.5996'
                    gradientTransform='matrix(0.9558 0.0739 -0.0616 -0.896 76.3661 953.2663)'
                  >
                    <stop offset='0' style={{ stopColor: '#FFFFFF' }}></stop>
                    <stop offset='0.4012' style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}></stop>
                    <stop offset='1' style={{ stopColor: '#FFFFFF' }}></stop>
                  </linearGradient>
                  <line className='st1' x1='1936.84' y1='46.97' x2='1233.17' y2='1118.55'></line>

                  <linearGradient
                    id='SVGID_3_'
                    gradientUnits='userSpaceOnUse'
                    x1='963.233'
                    y1='567.4513'
                    x2='1879.2639'
                    y2='567.4513'
                    gradientTransform='matrix(0.9287 0.1095 -0.1095 -0.8317 116.5227 900.9426)'
                  >
                    <stop offset='0' style={{ stopColor: '#FFFFFF' }}></stop>
                    <stop offset='0.5782' style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}></stop>
                    <stop offset='1' style={{ stopColor: '#FFFFFF' }}></stop>
                  </linearGradient>
                  <line className='st2' x1='1721' y1='51.97' x2='1027.61' y2='1117.27'></line>

                  <linearGradient
                    id='SVGID_4_'
                    gradientUnits='userSpaceOnUse'
                    x1='623.7047'
                    y1='531.6747'
                    x2='1721.4188'
                    y2='531.6747'
                    gradientTransform='matrix(0.8923 0.1653 -0.1653 -0.7465 193.2791 783.5307)'
                  >
                    <stop offset='0' style={{ stopColor: '#FFFFFF' }}></stop>
                    <stop offset='0.5306' style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}></stop>
                    <stop offset='1' style={{ stopColor: '#FFFFFF' }}></stop>
                  </linearGradient>
                  <line className='st3' x1='1500.88' y1='44.67' x2='802.47' y2='1116.25'></line>

                  <linearGradient
                    id='SVGID_5_'
                    gradientUnits='userSpaceOnUse'
                    x1='1303.8314'
                    y1='251.254'
                    x2='1950.0466'
                    y2='251.254'
                    gradientTransform='matrix(0.9503 0.0765 -0.0765 -0.8821 178.106 805.6204)'
                  >
                    <stop offset='0' style={{ stopColor: '#FFFFFF' }}></stop>
                    <stop offset='0' style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}></stop>
                    <stop
                      offset='0.1413'
                      style={{ stopColor: '#FFFFFF', stopOpacity: 0.1413 }}
                    ></stop>
                    <stop offset='1' style={{ stopColor: '#FFFFFF' }}></stop>
                  </linearGradient>
                  <line className='st4' x1='1972.18' y1='296.82' x2='1437.76' y2='1120.08'></line>

                  <linearGradient
                    id='SVGID_6_'
                    gradientUnits='userSpaceOnUse'
                    x1='991.7726'
                    y1='743.3976'
                    x2='1766.5626'
                    y2='743.3976'
                    gradientTransform='matrix(0.9402 -0.0908 0.0908 -0.8621 136.6814 1287.6116)'
                  >
                    <stop offset='0' style={{ stopColor: '#FFFFFF' }}></stop>
                    <stop offset='0' style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}></stop>
                    <stop offset='0.6834' style={{ stopColor: '#FFFFFF' }}></stop>
                  </linearGradient>
                  <line className='st5' x1='1809.99' y1='991.03' x2='1191.77' y2='51.97'></line>

                  <linearGradient
                    id='SVGID_7_'
                    gradientUnits='userSpaceOnUse'
                    x1='693.4987'
                    y1='744.8725'
                    x2='1589.3099'
                    y2='744.8725'
                    gradientTransform='matrix(0.9328 -0.107 0.1028 -0.836 147.5654 1324.2838)'
                  >
                    <stop offset='0' style={{ stopColor: '#FFFFFF' }}></stop>
                    <stop offset='0.4201' style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}></stop>
                    <stop offset='1' style={{ stopColor: '#FFFFFF' }}></stop>
                  </linearGradient>
                  <line className='st6' x1='1632.88' y1='1115.54' x2='944.81' y2='43.34'></line>
                </g>
              </g>
              <g>
                <image
                  className='diamond2 diamond'
                  width='525'
                  height='351'
                  href='/images/hero_background/diamond2.png'
                  transform='matrix(1 0 0 1 1587.017 68.0121)'
                ></image>

                <image
                  className='diamond6 diamond'
                  width='434'
                  height='292'
                  href='/images/hero_background/diamond6.png'
                  transform='matrix(1 0 0 1 1383.017 472.0121)'
                ></image>

                <image
                  className='diamond4 diamond'
                  width='440'
                  height='293'
                  href='/images/hero_background/diamond4.png'
                  transform='matrix(1 0 0 1 1386.017 239.0121)'
                ></image>

                <image
                  className='diamond5 diamond'
                  width='539'
                  height='597'
                  href='/images/hero_background/diamond5.png'
                  transform='matrix(1 0 0 1 1176.017 89.0121)'
                ></image>

                <image
                  className='diamond7 diamond'
                  width='532'
                  height='293'
                  href='/images/hero_background/diamond7.png'
                  transform='matrix(1 0 0 1 1519.017 551.0121)'
                ></image>

                <image
                  className='diamond10 diamond'
                  width='472'
                  height='300'
                  href='/images/hero_background/diamond10.png'
                  transform='matrix(1 0 0 1 1215.017 780.0121)'
                ></image>

                <image
                  className='diamond3 diamond'
                  width='448'
                  height='299'
                  href='/images/hero_background/diamond3.png'
                  transform='matrix(1 0 0 1 1249.017 151.0121)'
                ></image>

                <image
                  className='diamond8 diamond'
                  width='436'
                  height='355'
                  href='/images/hero_background/diamond11.png'
                  transform='matrix(1 0 0 1 1556.017 717.0121)'
                ></image>

                <image
                  className='diamond9 diamond'
                  width='500'
                  height='300'
                  href='/images/hero_background/diamond8.png'
                  transform='matrix(1 0 0 1 1315.017 620.0121)'
                ></image>
                <polygon
                  className='st7'
                  points='1475.02,152.39 1569.12,7.08 1659.02,151.37 1567.02,297.94 	'
                ></polygon>

                <radialGradient
                  id='SVGID_8_'
                  cx='1667.517'
                  cy='618.0121'
                  r='133.1529'
                  gradientTransform='matrix(-0.866 -0.5 0.524 -0.9076 2787.7764 2012.6998)'
                  gradientUnits='userSpaceOnUse'
                >
                  <stop offset='0' style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}></stop>
                  <stop
                    offset='0.4446'
                    style={{ stopColor: '#FFFFFF', stopOpacity: 0.4446 }}
                  ></stop>
                  <stop
                    offset='0.8123'
                    style={{ stopColor: '#FFFFFF', stopOpacity: 0.8123 }}
                  ></stop>
                  <stop offset='1' style={{ stopColor: '#FFFFFF' }}></stop>
                </radialGradient>
                <polygon
                  className='st8'
                  points='1575.52,617.89 1669.62,472.58 1759.52,616.87 1667.52,763.44 	'
                ></polygon>
              </g>
              <g>
                <g>
                  <g>
                    <path
                      className='st9'
                      d='M123.28,165.98h-5.79l14.77-75.73h5.79L123.28,165.98z'
                    ></path>
                    <path
                      className='st9'
                      d='M167.81,127.86h-13.22L149.52,154c-0.14,0.76-0.22,1.36-0.26,1.81c-0.04,0.45-0.05,0.84-0.05,1.19
				c0,1.72,0.62,2.94,1.86,3.67c1.24,0.72,3.03,1.08,5.37,1.08c1.45,0,2.82-0.1,4.13-0.31l-0.83,4.86c-0.9,0.14-1.74,0.26-2.53,0.36
				c-0.79,0.1-1.6,0.16-2.43,0.16c-3.38,0-6.1-0.69-8.16-2.07c-2.07-1.38-3.1-3.65-3.1-6.82c0-0.48,0.03-1.05,0.1-1.7
				c0.07-0.65,0.21-1.53,0.41-2.63l4.96-25.73h-7.75l0.21-1.14l8.58-4.34l1.96-9.92l6.1-2.79l-2.58,13.12h13.22L167.81,127.86z'
                    ></path>
                    <path
                      className='st9'
                      d='M189.61,94.18c0,1.52-0.36,3-1.08,4.44c-0.72,1.45-1.65,2.79-2.79,4.03c-1.14,1.24-2.43,2.36-3.87,3.36
				c-1.45,1-2.86,1.88-4.24,2.63l-0.93-1.34c2.2-1.65,3.96-3.24,5.27-4.75c1.31-1.51,2.34-3.06,3.1-4.65
				c-1.24-0.21-2.26-0.71-3.05-1.5c-0.79-0.79-1.19-1.81-1.19-3.05c0-1.17,0.41-2.17,1.24-3c0.83-0.83,1.82-1.24,3-1.24
				c1.03,0,2.05,0.45,3.05,1.34C189.11,91.35,189.61,92.59,189.61,94.18z'
                    ></path>
                    <path
                      className='st9'
                      d='M214.82,130.96c-1.52-1.31-3.1-2.34-4.75-3.1c-1.65-0.76-3.48-1.14-5.48-1.14c-2.96,0-5.2,0.77-6.72,2.32
				c-1.52,1.55-2.27,3.22-2.27,5.01c0,1.79,0.81,3.27,2.43,4.44c1.62,1.17,3.49,2.31,5.63,3.41c2.69,1.45,4.96,3,6.82,4.65
				c1.86,1.65,2.79,3.93,2.79,6.82c0,1.86-0.38,3.62-1.14,5.27c-0.76,1.65-1.81,3.1-3.15,4.34c-1.34,1.24-2.93,2.21-4.75,2.89
				c-1.83,0.69-3.84,1.03-6.04,1.03c-3.03,0-5.8-0.57-8.32-1.7c-2.52-1.14-4.77-2.6-6.77-4.39l3.41-4.03
				c1.79,1.59,3.67,2.86,5.63,3.82c1.96,0.97,4.11,1.45,6.46,1.45c2.96,0,5.27-0.76,6.92-2.27c1.65-1.51,2.48-3.37,2.48-5.58
				c0-2-0.83-3.6-2.48-4.8c-1.65-1.2-3.58-2.39-5.79-3.56c-1.24-0.62-2.43-1.27-3.56-1.96c-1.14-0.69-2.12-1.46-2.94-2.32
				c-0.83-0.86-1.5-1.84-2.01-2.94c-0.52-1.1-0.77-2.38-0.77-3.82c0-1.72,0.36-3.36,1.08-4.91s1.74-2.91,3.05-4.08
				c1.31-1.17,2.89-2.1,4.75-2.79c1.86-0.69,3.89-1.03,6.1-1.03c2.41,0,4.7,0.45,6.87,1.34c2.17,0.9,4.08,2.07,5.73,3.51
				L214.82,130.96z'
                    ></path>
                    <path
                      className='st9'
                      d='M296.54,165.98l-4.86-23.25h-34.3l-14.05,23.25h-6.2l47.22-76.97h0.93l17.36,76.97H296.54z M282.8,100.89
				l-22.42,36.99h30.17L282.8,100.89z'
                    ></path>
                    <path
                      className='st9'
                      d='M353.16,139.95c0,3.44-0.6,6.78-1.81,10.02c-1.21,3.24-2.88,6.1-5.01,8.58c-2.14,2.48-4.65,4.48-7.54,5.99
				c-2.89,1.51-6.06,2.27-9.51,2.27c-2.55,0-4.87-0.48-6.97-1.45c-2.1-0.96-3.87-2.13-5.32-3.51l-0.83,4.13h-5.68l14.77-75.73h5.79
				l-7.03,36.26c1.72-1.38,3.67-2.48,5.84-3.31c2.17-0.83,4.53-1.24,7.08-1.24c2.41,0,4.61,0.46,6.61,1.39
				c2,0.93,3.7,2.19,5.11,3.77c1.41,1.59,2.51,3.48,3.31,5.68C352.76,135.02,353.16,137.4,353.16,139.95z M347.47,140.88
				c0-4.27-1.1-7.65-3.31-10.12c-2.21-2.48-5.17-3.72-8.89-3.72c-2.21,0-4.37,0.46-6.51,1.39c-2.14,0.93-4.06,2.19-5.79,3.77
				l-4.86,24.69c1.24,1.45,2.81,2.63,4.7,3.56c1.89,0.93,4.05,1.39,6.46,1.39c2.62,0,5.03-0.58,7.23-1.76
				c2.2-1.17,4.11-2.72,5.73-4.65c1.62-1.93,2.89-4.15,3.82-6.66C347.01,146.27,347.47,143.63,347.47,140.88z'
                    ></path>
                    <path
                      className='st9'
                      d='M401.2,140.36c0,3.72-0.72,7.34-2.17,10.85c-1.45,3.51-3.41,6.54-5.89,9.09c-2,2-4.29,3.58-6.87,4.75
				c-2.58,1.17-5.36,1.76-8.32,1.76c-2.55,0-4.89-0.45-7.03-1.34c-2.14-0.89-3.96-2.15-5.48-3.77c-1.52-1.62-2.69-3.55-3.51-5.79
				c-0.83-2.24-1.24-4.73-1.24-7.49c0-3.72,0.72-7.34,2.17-10.85c1.45-3.51,3.41-6.54,5.89-9.09c2-2,4.29-3.58,6.87-4.75
				c2.58-1.17,5.35-1.76,8.32-1.76c2.55,0,4.89,0.45,7.03,1.34c2.13,0.9,3.96,2.15,5.48,3.77c1.51,1.62,2.69,3.55,3.51,5.79
				C400.78,135.11,401.2,137.61,401.2,140.36z M395.62,140.67c0-4.13-1.07-7.45-3.2-9.97c-2.14-2.51-5.1-3.77-8.89-3.77
				c-2.07,0-4.01,0.45-5.84,1.34c-1.83,0.9-3.5,2.07-5.01,3.51c-2,1.93-3.56,4.32-4.7,7.18c-1.14,2.86-1.7,5.91-1.7,9.14
				c0,4.13,1.07,7.46,3.2,9.97c2.13,2.52,5.06,3.77,8.78,3.77c2.07,0,4.03-0.45,5.89-1.34c1.86-0.89,3.51-2.07,4.96-3.51
				c2-1.93,3.58-4.32,4.75-7.18C395.03,146.96,395.62,143.91,395.62,140.67z'
                    ></path>
                    <path
                      className='st9'
                      d='M441.39,165.98h-5.58l1.45-7.23c-2.55,2.27-5.27,4.18-8.16,5.73c-2.89,1.55-5.89,2.32-8.99,2.32
				c-3.72,0-6.47-1.05-8.27-3.15c-1.79-2.1-2.69-4.8-2.69-8.11c0-1.45,0.17-3.13,0.52-5.06l5.48-27.69h5.79l-5.37,27.28
				c-0.21,0.9-0.35,1.69-0.41,2.38c-0.07,0.69-0.1,1.31-0.1,1.86c0,5.03,2.51,7.54,7.54,7.54c2.75,0,5.41-0.77,7.96-2.32
				c2.55-1.55,5.03-3.46,7.44-5.73l5.99-30.99h5.79L441.39,165.98z'
                    ></path>
                    <path
                      className='st9'
                      d='M483.02,127.86H469.8L464.73,154c-0.14,0.76-0.22,1.36-0.26,1.81c-0.04,0.45-0.05,0.84-0.05,1.19
				c0,1.72,0.62,2.94,1.86,3.67c1.24,0.72,3.03,1.08,5.37,1.08c1.45,0,2.82-0.1,4.13-0.31l-0.83,4.86c-0.9,0.14-1.74,0.26-2.53,0.36
				c-0.79,0.1-1.6,0.16-2.43,0.16c-3.38,0-6.1-0.69-8.16-2.07c-2.07-1.38-3.1-3.65-3.1-6.82c0-0.48,0.03-1.05,0.1-1.7
				c0.07-0.65,0.21-1.53,0.41-2.63l4.96-25.73h-7.75l0.21-1.14l8.58-4.34l1.96-9.92l6.1-2.79l-2.58,13.12h13.22L483.02,127.86z'
                    ></path>
                  </g>
                  <g>
                    <path
                      className='st9'
                      d='M328.34,215.73h-33.86l-17.96,91.71H249.5l17.96-91.71h-33.86l4.77-24.8h94.73L328.34,215.73z'
                    ></path>
                    <path
                      className='st9'
                      d='M354.88,307.45h-27.02l22.57-116.51h27.02L354.88,307.45z'
                    ></path>
                    <path
                      className='st9'
                      d='M499.21,307.45h-26.23l9.54-56.43l-37.67,40.05h-2.86l-24-41.33l-12.4,57.7h-26.07l28.29-117.3l3.66-0.32
				l38.15,66.6l63.26-66.6l3.66,0.32L499.21,307.45z'
                    ></path>
                    <path
                      className='st9'
                      d='M612.53,215.73h-44.82l-3.97,20.82h40.53l-4.61,23.52h-40.37L555,282.81h44.51l-4.93,24.64h-71.53
				l22.57-116.51h71.69L612.53,215.73z'
                    ></path>
                  </g>
                </g>
                <g>
                  <path
                    className='st9'
                    d='M131.45,351.29c0,0.99-0.21,1.94-0.63,2.87c-0.42,0.92-1.04,1.74-1.87,2.44c-0.83,0.7-1.86,1.26-3.09,1.68
			c-1.23,0.42-2.66,0.63-4.29,0.63c-1.11,0-2.19-0.04-3.24-0.13c-1.05-0.09-2.08-0.19-3.09-0.31v-26.74c1.01-0.12,2-0.23,2.96-0.31
			c0.96-0.09,2-0.13,3.11-0.13c1.63,0,3.03,0.18,4.22,0.55c1.18,0.37,2.16,0.87,2.94,1.5c0.78,0.63,1.36,1.36,1.74,2.2
			c0.38,0.84,0.57,1.71,0.57,2.63c0,1.63-0.48,2.98-1.42,4.07c-0.95,1.09-2.35,1.87-4.2,2.37c2.17,0.39,3.76,1.2,4.77,2.42
			C130.95,348.24,131.45,349.66,131.45,351.29z M128.68,338.38c0-1.55-0.59-2.83-1.76-3.83c-1.17-1-2.99-1.5-5.46-1.5
			c-1.46,0-2.84,0.11-4.14,0.33v10.39c0.57,0.05,1.13,0.07,1.68,0.07c0.55,0,1.12,0,1.68,0c1.38,0,2.57-0.14,3.57-0.43
			c1-0.28,1.82-0.67,2.48-1.16c0.65-0.49,1.14-1.07,1.46-1.74C128.52,339.86,128.68,339.14,128.68,338.38z M129.31,351.18
			c0-1.65-0.7-3.01-2.11-4.07c-1.41-1.06-3.53-1.59-6.36-1.59c-0.49,0-1.06,0-1.7,0c-0.64,0-1.25,0.02-1.81,0.07v11.17
			c0.67,0.1,1.39,0.18,2.16,0.24c0.78,0.06,1.55,0.09,2.31,0.09c1.23,0,2.32-0.15,3.25-0.46c0.94-0.31,1.72-0.73,2.35-1.26
			c0.63-0.53,1.1-1.15,1.42-1.87C129.15,352.79,129.31,352.02,129.31,351.18z'
                  ></path>
                  <path
                    className='st9'
                    d='M151.31,358.65l-7.4-11.02c-0.37,0.05-0.75,0.09-1.15,0.11c-0.39,0.02-0.8,0.04-1.22,0.04
			c-0.49,0-1.05-0.01-1.68-0.04c-0.63-0.02-1.17-0.06-1.61-0.11v11.02h-2.11V331.8c1.01-0.12,1.97-0.23,2.87-0.31
			c0.9-0.09,1.88-0.13,2.94-0.13c1.68,0,3.16,0.2,4.44,0.59c1.28,0.39,2.35,0.94,3.22,1.65c0.86,0.7,1.52,1.55,1.96,2.53
			c0.44,0.99,0.67,2.07,0.67,3.25c0,1.85-0.54,3.46-1.63,4.85c-1.09,1.38-2.64,2.37-4.66,2.96l7.88,11.47H151.31z M150.02,339.49
			c0-0.91-0.17-1.75-0.5-2.52c-0.33-0.76-0.83-1.42-1.5-1.98s-1.49-0.99-2.46-1.31c-0.97-0.32-2.1-0.48-3.38-0.48
			c-1.31,0-2.61,0.11-3.92,0.33v12.24c0.47,0.05,1.01,0.1,1.63,0.15c0.62,0.05,1.21,0.07,1.78,0.07c2.74,0,4.81-0.59,6.23-1.76
			C149.31,343.07,150.02,341.49,150.02,339.49z'
                  ></path>
                  <path className='st9' d='M158.12,358.65v-27.11h2.07v27.11H158.12z'></path>
                  <path
                    className='st9'
                    d='M186.86,359.06l-18.05-23.01v22.6h-2.03v-27.41l0.48-0.11l17.9,22.75v-22.34h2.07v27.44L186.86,359.06z'
                  ></path>
                  <path
                    className='st9'
                    d='M216.78,345.74v9.95c-1.33,1.06-2.81,1.88-4.42,2.46c-1.62,0.58-3.42,0.87-5.42,0.87
			c-1.85,0-3.64-0.31-5.36-0.94c-1.73-0.63-3.25-1.53-4.59-2.72c-1.33-1.18-2.4-2.64-3.2-4.36c-0.8-1.73-1.2-3.69-1.2-5.88
			c0-2.17,0.4-4.12,1.2-5.86c0.8-1.74,1.86-3.21,3.18-4.42c1.32-1.21,2.82-2.13,4.51-2.77c1.69-0.64,3.42-0.96,5.2-0.96
			c1.08,0,2.09,0.08,3.01,0.24c0.92,0.16,1.79,0.39,2.59,0.68c0.8,0.3,1.57,0.65,2.29,1.05c0.73,0.41,1.44,0.86,2.13,1.35
			l-1.11,1.63c-1.28-0.94-2.66-1.68-4.12-2.24c-1.47-0.55-3.06-0.84-4.79-0.87c-1.68,0-3.24,0.3-4.68,0.91
			c-1.44,0.6-2.69,1.44-3.75,2.52c-1.06,1.07-1.89,2.35-2.5,3.85c-0.6,1.49-0.91,3.13-0.91,4.9c0,1.82,0.32,3.48,0.96,4.97
			c0.64,1.49,1.52,2.76,2.63,3.81c1.11,1.05,2.4,1.86,3.88,2.42c1.48,0.57,3.05,0.85,4.7,0.85c1.5,0,2.89-0.2,4.16-0.61
			c1.27-0.41,2.45-0.99,3.53-1.76v-7.14h-7.73v-1.92H216.78z'
                  ></path>
                  <path className='st9' d='M222.62,358.65v-27.11h2.07v27.11H222.62z'></path>
                  <path
                    className='st9'
                    d='M251.36,359.06l-18.05-23.01v22.6h-2.03v-27.41l0.48-0.11l17.9,22.75v-22.34h2.07v27.44L251.36,359.06z'
                  ></path>
                  <path
                    className='st9'
                    d='M281.28,345.74v9.95c-1.33,1.06-2.81,1.88-4.42,2.46c-1.62,0.58-3.42,0.87-5.42,0.87
			c-1.85,0-3.64-0.31-5.36-0.94c-1.73-0.63-3.25-1.53-4.59-2.72c-1.33-1.18-2.4-2.64-3.2-4.36c-0.8-1.73-1.2-3.69-1.2-5.88
			c0-2.17,0.4-4.12,1.2-5.86c0.8-1.74,1.86-3.21,3.18-4.42c1.32-1.21,2.82-2.13,4.51-2.77c1.69-0.64,3.42-0.96,5.2-0.96
			c1.08,0,2.09,0.08,3.01,0.24c0.92,0.16,1.79,0.39,2.59,0.68c0.8,0.3,1.57,0.65,2.29,1.05c0.73,0.41,1.44,0.86,2.13,1.35
			l-1.11,1.63c-1.28-0.94-2.66-1.68-4.12-2.24c-1.47-0.55-3.06-0.84-4.79-0.87c-1.68,0-3.24,0.3-4.68,0.91
			c-1.44,0.6-2.69,1.44-3.75,2.52c-1.06,1.07-1.89,2.35-2.5,3.85c-0.6,1.49-0.91,3.13-0.91,4.9c0,1.82,0.32,3.48,0.96,4.97
			c0.64,1.49,1.52,2.76,2.63,3.81c1.11,1.05,2.4,1.86,3.88,2.42c1.48,0.57,3.05,0.85,4.7,0.85c1.5,0,2.89-0.2,4.16-0.61
			c1.27-0.41,2.45-0.99,3.53-1.76v-7.14h-7.73v-1.92H281.28z'
                  ></path>
                  <path
                    className='st9'
                    d='M316.9,344.85c0,1.87-0.33,3.66-0.98,5.34c-0.65,1.69-1.62,3.18-2.88,4.48c-1.27,1.29-2.86,2.32-4.77,3.09
			c-1.91,0.76-4.12,1.15-6.64,1.15c-1.31,0-2.55-0.04-3.72-0.13c-1.17-0.09-2.18-0.19-3.01-0.31v-26.74
			c0.84-0.12,1.82-0.23,2.96-0.31c1.13-0.09,2.34-0.13,3.62-0.13c2.52,0,4.73,0.38,6.66,1.15c1.92,0.76,3.53,1.78,4.83,3.03
			s2.27,2.7,2.94,4.33C316.56,341.41,316.9,343.1,316.9,344.85z M314.68,344.85c0-1.63-0.28-3.15-0.85-4.57
			c-0.57-1.42-1.41-2.66-2.51-3.74s-2.48-1.92-4.12-2.53c-1.64-0.62-3.53-0.92-5.68-0.92c-0.86,0-1.7,0.02-2.51,0.07
			c-0.81,0.05-1.49,0.12-2.03,0.22v23.41c0.57,0.1,1.24,0.17,2.03,0.22c0.79,0.05,1.69,0.07,2.7,0.07c2.15,0,4.03-0.31,5.66-0.94
			c1.63-0.63,2.98-1.49,4.07-2.59c1.08-1.1,1.9-2.39,2.44-3.88C314.4,348.19,314.68,346.58,314.68,344.85z'
                  ></path>
                  <path className='st9' d='M322.41,358.65v-27.11h2.07v27.11H322.41z'></path>
                  <path
                    className='st9'
                    d='M350.55,358.65l-3.51-8.28h-12.72l-3.48,8.28h-2.18l11.87-27.55h0.37l11.87,27.55H350.55z M340.68,335.31
			l-5.58,13.28h11.17L340.68,335.31z'
                  ></path>
                  <path
                    className='st9'
                    d='M379.92,345.74v9.95c-1.33,1.06-2.81,1.88-4.42,2.46c-1.62,0.58-3.42,0.87-5.42,0.87
			c-1.85,0-3.64-0.31-5.36-0.94c-1.73-0.63-3.25-1.53-4.59-2.72c-1.33-1.18-2.4-2.64-3.2-4.36c-0.8-1.73-1.2-3.69-1.2-5.88
			c0-2.17,0.4-4.12,1.2-5.86c0.8-1.74,1.86-3.21,3.18-4.42c1.32-1.21,2.82-2.13,4.51-2.77c1.69-0.64,3.42-0.96,5.2-0.96
			c1.08,0,2.09,0.08,3.01,0.24c0.92,0.16,1.79,0.39,2.59,0.68c0.8,0.3,1.57,0.65,2.29,1.05c0.73,0.41,1.44,0.86,2.13,1.35
			l-1.11,1.63c-1.28-0.94-2.66-1.68-4.12-2.24c-1.47-0.55-3.06-0.84-4.79-0.87c-1.68,0-3.24,0.3-4.68,0.91
			c-1.44,0.6-2.69,1.44-3.75,2.52c-1.06,1.07-1.89,2.35-2.5,3.85c-0.6,1.49-0.91,3.13-0.91,4.9c0,1.82,0.32,3.48,0.96,4.97
			c0.64,1.49,1.52,2.76,2.63,3.81c1.11,1.05,2.4,1.86,3.88,2.42c1.48,0.57,3.05,0.85,4.7,0.85c1.5,0,2.89-0.2,4.16-0.61
			c1.27-0.41,2.45-0.99,3.53-1.76v-7.14h-7.73v-1.92H379.92z'
                  ></path>
                  <path
                    className='st9'
                    d='M405.7,359.06l-18.05-23.01v22.6h-2.03v-27.41l0.48-0.11l17.9,22.75v-22.34h2.07v27.44L405.7,359.06z'
                  ></path>
                  <path
                    className='st9'
                    d='M438.28,345.11c0,2-0.35,3.85-1.05,5.55c-0.7,1.7-1.66,3.17-2.88,4.42c-1.22,1.25-2.65,2.21-4.29,2.9
			c-1.64,0.69-3.4,1.04-5.27,1.04c-1.85,0-3.59-0.35-5.21-1.04c-1.63-0.69-3.05-1.66-4.25-2.9c-1.21-1.24-2.16-2.72-2.85-4.42
			c-0.69-1.7-1.04-3.55-1.04-5.55c0-2,0.35-3.85,1.05-5.57c0.7-1.71,1.66-3.19,2.88-4.44c1.22-1.24,2.65-2.22,4.29-2.92
			c1.64-0.7,3.4-1.05,5.27-1.05c1.85,0,3.59,0.35,5.21,1.05c1.63,0.7,3.05,1.68,4.25,2.92c1.21,1.25,2.16,2.72,2.85,4.44
			C437.94,341.26,438.28,343.11,438.28,345.11z M436.02,345.11c0-1.7-0.28-3.29-0.83-4.77c-0.55-1.48-1.33-2.76-2.31-3.85
			c-0.99-1.08-2.16-1.94-3.53-2.55c-1.37-0.62-2.87-0.92-4.49-0.92c-1.63,0-3.13,0.31-4.49,0.92c-1.37,0.62-2.55,1.47-3.53,2.55
			c-0.99,1.09-1.76,2.37-2.31,3.85c-0.55,1.48-0.83,3.07-0.83,4.77c0,1.7,0.28,3.29,0.83,4.75c0.55,1.47,1.33,2.74,2.31,3.83
			c0.99,1.09,2.16,1.94,3.53,2.55c1.37,0.62,2.87,0.92,4.49,0.92c1.63,0,3.13-0.31,4.49-0.92c1.37-0.62,2.55-1.47,3.53-2.55
			c0.99-1.08,1.76-2.36,2.31-3.83C435.75,348.4,436.02,346.81,436.02,345.11z'
                  ></path>
                  <path
                    className='st9'
                    d='M457.59,351.66c0,1.11-0.22,2.12-0.65,3.03c-0.43,0.91-1.02,1.7-1.78,2.35c-0.75,0.65-1.63,1.16-2.63,1.52
			c-1,0.36-2.08,0.54-3.24,0.54c-1.46,0-2.83-0.22-4.12-0.67c-1.29-0.44-2.53-1.06-3.72-1.85l1-1.81c1.11,0.74,2.25,1.33,3.42,1.78
			c1.17,0.44,2.37,0.67,3.61,0.67c0.89,0,1.7-0.14,2.44-0.41c0.74-0.27,1.37-0.64,1.9-1.11c0.53-0.47,0.94-1.03,1.22-1.68
			c0.28-0.65,0.43-1.35,0.43-2.09c0-0.79-0.13-1.47-0.39-2.05c-0.26-0.58-0.64-1.11-1.15-1.59c-0.51-0.48-1.13-0.92-1.87-1.33
			c-0.74-0.41-1.59-0.83-2.55-1.28c-1.04-0.47-1.97-0.94-2.81-1.42c-0.84-0.48-1.55-1.02-2.13-1.61c-0.58-0.59-1.02-1.25-1.33-1.98
			c-0.31-0.73-0.46-1.56-0.46-2.5c0-0.99,0.2-1.9,0.59-2.76c0.39-0.85,0.95-1.6,1.66-2.24c0.71-0.64,1.57-1.15,2.57-1.52
			c1-0.37,2.1-0.55,3.31-0.55c1.18,0,2.3,0.17,3.35,0.52c1.05,0.35,1.97,0.79,2.76,1.33l-1,1.74c-0.69-0.49-1.49-0.9-2.39-1.22
			c-0.9-0.32-1.87-0.48-2.9-0.48c-0.91,0-1.73,0.13-2.46,0.39c-0.73,0.26-1.34,0.62-1.85,1.07c-0.51,0.46-0.89,0.99-1.17,1.59
			c-0.27,0.6-0.41,1.26-0.41,1.98c0,0.74,0.14,1.39,0.41,1.94c0.27,0.55,0.65,1.06,1.15,1.52c0.49,0.46,1.08,0.88,1.78,1.26
			c0.69,0.38,1.44,0.76,2.26,1.13c1.16,0.52,2.18,1.04,3.07,1.55c0.89,0.52,1.63,1.08,2.24,1.68c0.6,0.6,1.06,1.28,1.37,2.02
			C457.43,349.85,457.59,350.7,457.59,351.66z'
                  ></path>
                  <path
                    className='st9'
                    d='M471.97,333.39v25.26h-2.11v-25.26h-9.21v-1.85h20.49v1.85H471.97z'
                  ></path>
                  <path className='st9' d='M485.62,358.65v-27.11h2.07v27.11H485.62z'></path>
                  <path
                    className='st9'
                    d='M516.76,356.06c-1.23,0.81-2.61,1.51-4.14,2.09c-1.53,0.58-3.27,0.87-5.21,0.87c-1.95,0-3.79-0.33-5.51-0.98
			c-1.73-0.65-3.24-1.58-4.53-2.77c-1.29-1.2-2.31-2.65-3.05-4.36c-0.74-1.71-1.11-3.63-1.11-5.75c0-2.12,0.38-4.04,1.13-5.77
			c0.75-1.73,1.78-3.21,3.07-4.44c1.29-1.23,2.8-2.18,4.53-2.85c1.73-0.67,3.55-1,5.47-1c1.97,0,3.73,0.28,5.27,0.85
			c1.54,0.57,2.93,1.27,4.16,2.11l-0.92,1.74c-1.26-0.81-2.6-1.48-4.03-2c-1.43-0.52-2.96-0.78-4.59-0.78
			c-1.65,0-3.2,0.28-4.64,0.85c-1.44,0.57-2.7,1.38-3.77,2.44c-1.07,1.06-1.91,2.34-2.51,3.83c-0.6,1.49-0.91,3.15-0.91,4.97
			c0,1.82,0.31,3.48,0.92,4.96c0.62,1.48,1.47,2.74,2.55,3.77c1.08,1.04,2.35,1.84,3.81,2.4c1.45,0.57,3.01,0.85,4.66,0.85
			c1.63,0,3.16-0.26,4.59-0.78c1.43-0.52,2.77-1.17,4.03-1.96L516.76,356.06z'
                  ></path>
                  <path
                    className='st9'
                    d='M535.59,351.66c0,1.11-0.22,2.12-0.65,3.03c-0.43,0.91-1.02,1.7-1.78,2.35c-0.75,0.65-1.63,1.16-2.63,1.52
			c-1,0.36-2.08,0.54-3.24,0.54c-1.46,0-2.83-0.22-4.12-0.67c-1.29-0.44-2.53-1.06-3.72-1.85l1-1.81c1.11,0.74,2.25,1.33,3.42,1.78
			c1.17,0.44,2.37,0.67,3.61,0.67c0.89,0,1.7-0.14,2.44-0.41c0.74-0.27,1.37-0.64,1.9-1.11c0.53-0.47,0.94-1.03,1.22-1.68
			c0.28-0.65,0.43-1.35,0.43-2.09c0-0.79-0.13-1.47-0.39-2.05c-0.26-0.58-0.64-1.11-1.15-1.59c-0.51-0.48-1.13-0.92-1.87-1.33
			c-0.74-0.41-1.59-0.83-2.55-1.28c-1.04-0.47-1.97-0.94-2.81-1.42c-0.84-0.48-1.55-1.02-2.13-1.61c-0.58-0.59-1.02-1.25-1.33-1.98
			c-0.31-0.73-0.46-1.56-0.46-2.5c0-0.99,0.2-1.9,0.59-2.76c0.39-0.85,0.95-1.6,1.66-2.24c0.71-0.64,1.57-1.15,2.57-1.52
			c1-0.37,2.1-0.55,3.31-0.55c1.18,0,2.3,0.17,3.35,0.52c1.05,0.35,1.97,0.79,2.76,1.33l-1,1.74c-0.69-0.49-1.49-0.9-2.39-1.22
			c-0.9-0.32-1.87-0.48-2.9-0.48c-0.91,0-1.73,0.13-2.46,0.39c-0.73,0.26-1.34,0.62-1.85,1.07c-0.51,0.46-0.89,0.99-1.17,1.59
			c-0.27,0.6-0.41,1.26-0.41,1.98c0,0.74,0.14,1.39,0.41,1.94c0.27,0.55,0.65,1.06,1.15,1.52c0.49,0.46,1.08,0.88,1.78,1.26
			c0.69,0.38,1.44,0.76,2.26,1.13c1.16,0.52,2.18,1.04,3.07,1.55c0.89,0.52,1.63,1.08,2.24,1.68c0.6,0.6,1.06,1.28,1.37,2.02
			C535.43,349.85,535.59,350.7,535.59,351.66z'
                  ></path>
                  <path
                    className='st9'
                    d='M571.17,356.06c-1.23,0.81-2.61,1.51-4.14,2.09c-1.53,0.58-3.27,0.87-5.21,0.87c-1.95,0-3.79-0.33-5.51-0.98
			c-1.73-0.65-3.24-1.58-4.53-2.77c-1.29-1.2-2.31-2.65-3.05-4.36c-0.74-1.71-1.11-3.63-1.11-5.75c0-2.12,0.38-4.04,1.13-5.77
			c0.75-1.73,1.78-3.21,3.07-4.44c1.29-1.23,2.8-2.18,4.53-2.85c1.73-0.67,3.55-1,5.47-1c1.97,0,3.73,0.28,5.27,0.85
			c1.54,0.57,2.93,1.27,4.16,2.11l-0.92,1.74c-1.26-0.81-2.6-1.48-4.03-2c-1.43-0.52-2.96-0.78-4.59-0.78
			c-1.65,0-3.2,0.28-4.64,0.85c-1.44,0.57-2.7,1.38-3.77,2.44c-1.07,1.06-1.91,2.34-2.51,3.83c-0.6,1.49-0.91,3.15-0.91,4.97
			c0,1.82,0.31,3.48,0.92,4.96c0.62,1.48,1.47,2.74,2.55,3.77c1.08,1.04,2.35,1.84,3.81,2.4c1.45,0.57,3.01,0.85,4.66,0.85
			c1.63,0,3.16-0.26,4.59-0.78c1.43-0.52,2.77-1.17,4.03-1.96L571.17,356.06z'
                  ></path>
                  <path
                    className='st9'
                    d='M576.05,358.65v-27.11h2.07v25.22h13.24v1.89H576.05z'
                  ></path>
                  <path
                    className='st9'
                    d='M620.8,345.11c0,2-0.35,3.85-1.05,5.55c-0.7,1.7-1.66,3.17-2.88,4.42c-1.22,1.25-2.65,2.21-4.29,2.9
			c-1.64,0.69-3.4,1.04-5.27,1.04c-1.85,0-3.59-0.35-5.21-1.04c-1.63-0.69-3.05-1.66-4.25-2.9c-1.21-1.24-2.16-2.72-2.85-4.42
			c-0.69-1.7-1.04-3.55-1.04-5.55c0-2,0.35-3.85,1.05-5.57c0.7-1.71,1.66-3.19,2.88-4.44c1.22-1.24,2.65-2.22,4.29-2.92
			c1.64-0.7,3.4-1.05,5.27-1.05c1.85,0,3.59,0.35,5.21,1.05c1.63,0.7,3.05,1.68,4.25,2.92c1.21,1.25,2.16,2.72,2.85,4.44
			C620.46,341.26,620.8,343.11,620.8,345.11z M618.55,345.11c0-1.7-0.28-3.29-0.83-4.77c-0.55-1.48-1.33-2.76-2.31-3.85
			c-0.99-1.08-2.16-1.94-3.53-2.55c-1.37-0.62-2.87-0.92-4.49-0.92c-1.63,0-3.13,0.31-4.49,0.92c-1.37,0.62-2.55,1.47-3.53,2.55
			c-0.99,1.09-1.76,2.37-2.31,3.85c-0.55,1.48-0.83,3.07-0.83,4.77c0,1.7,0.28,3.29,0.83,4.75c0.55,1.47,1.33,2.74,2.31,3.83
			c0.99,1.09,2.16,1.94,3.53,2.55c1.37,0.62,2.87,0.92,4.49,0.92c1.63,0,3.13-0.31,4.49-0.92c1.37-0.62,2.55-1.47,3.53-2.55
			c0.99-1.08,1.76-2.36,2.31-3.83C618.27,348.4,618.55,346.81,618.55,345.11z'
                  ></path>
                  <path
                    className='st9'
                    d='M640.11,351.66c0,1.11-0.22,2.12-0.65,3.03c-0.43,0.91-1.02,1.7-1.78,2.35c-0.75,0.65-1.63,1.16-2.63,1.52
			c-1,0.36-2.08,0.54-3.24,0.54c-1.46,0-2.83-0.22-4.12-0.67c-1.29-0.44-2.53-1.06-3.72-1.85l1-1.81c1.11,0.74,2.25,1.33,3.42,1.78
			c1.17,0.44,2.37,0.67,3.61,0.67c0.89,0,1.7-0.14,2.44-0.41c0.74-0.27,1.37-0.64,1.9-1.11c0.53-0.47,0.94-1.03,1.22-1.68
			c0.28-0.65,0.43-1.35,0.43-2.09c0-0.79-0.13-1.47-0.39-2.05c-0.26-0.58-0.64-1.11-1.15-1.59c-0.51-0.48-1.13-0.92-1.87-1.33
			c-0.74-0.41-1.59-0.83-2.55-1.28c-1.04-0.47-1.97-0.94-2.81-1.42c-0.84-0.48-1.55-1.02-2.13-1.61c-0.58-0.59-1.02-1.25-1.33-1.98
			c-0.31-0.73-0.46-1.56-0.46-2.5c0-0.99,0.2-1.9,0.59-2.76c0.39-0.85,0.95-1.6,1.66-2.24c0.71-0.64,1.57-1.15,2.57-1.52
			c1-0.37,2.1-0.55,3.31-0.55c1.18,0,2.3,0.17,3.35,0.52c1.05,0.35,1.97,0.79,2.76,1.33l-1,1.74c-0.69-0.49-1.49-0.9-2.39-1.22
			c-0.9-0.32-1.87-0.48-2.9-0.48c-0.91,0-1.73,0.13-2.46,0.39c-0.73,0.26-1.34,0.62-1.85,1.07c-0.51,0.46-0.89,0.99-1.17,1.59
			c-0.27,0.6-0.41,1.26-0.41,1.98c0,0.74,0.14,1.39,0.41,1.94c0.27,0.55,0.65,1.06,1.15,1.52c0.49,0.46,1.08,0.88,1.78,1.26
			c0.69,0.38,1.44,0.76,2.26,1.13c1.16,0.52,2.18,1.04,3.07,1.55c0.89,0.52,1.63,1.08,2.24,1.68c0.6,0.6,1.06,1.28,1.37,2.02
			C639.95,349.85,640.11,350.7,640.11,351.66z'
                  ></path>
                  <path
                    className='st9'
                    d='M645.29,358.65v-27.11h15.31v1.85h-13.24v10.58h11.95v1.85h-11.95v10.95h13.24v1.89H645.29z'
                  ></path>
                  <path
                    className='st9'
                    d='M681.27,358.65l-7.4-11.02c-0.37,0.05-0.75,0.09-1.15,0.11c-0.39,0.02-0.8,0.04-1.22,0.04
			c-0.49,0-1.05-0.01-1.68-0.04c-0.63-0.02-1.17-0.06-1.61-0.11v11.02h-2.11V331.8c1.01-0.12,1.97-0.23,2.87-0.31
			c0.9-0.09,1.88-0.13,2.94-0.13c1.68,0,3.16,0.2,4.44,0.59c1.28,0.39,2.35,0.94,3.22,1.65c0.86,0.7,1.52,1.55,1.96,2.53
			c0.44,0.99,0.67,2.07,0.67,3.25c0,1.85-0.54,3.46-1.63,4.85c-1.09,1.38-2.64,2.37-4.66,2.96l7.88,11.47H681.27z M679.98,339.49
			c0-0.91-0.17-1.75-0.5-2.52c-0.33-0.76-0.83-1.42-1.5-1.98c-0.67-0.55-1.49-0.99-2.46-1.31c-0.97-0.32-2.1-0.48-3.38-0.48
			c-1.31,0-2.61,0.11-3.92,0.33v12.24c0.47,0.05,1.01,0.1,1.63,0.15c0.62,0.05,1.21,0.07,1.78,0.07c2.74,0,4.81-0.59,6.23-1.76
			C679.27,343.07,679.98,341.49,679.98,339.49z'
                  ></path>
                  <path
                    className='st9'
                    d='M705.05,333.39v25.26h-2.11v-25.26h-9.21v-1.85h20.49v1.85H705.05z'
                  ></path>
                  <path
                    className='st9'
                    d='M744.33,345.11c0,2-0.35,3.85-1.05,5.55c-0.7,1.7-1.66,3.17-2.88,4.42c-1.22,1.25-2.65,2.21-4.29,2.9
			c-1.64,0.69-3.4,1.04-5.27,1.04c-1.85,0-3.59-0.35-5.21-1.04c-1.63-0.69-3.05-1.66-4.25-2.9c-1.21-1.24-2.16-2.72-2.85-4.42
			c-0.69-1.7-1.04-3.55-1.04-5.55c0-2,0.35-3.85,1.05-5.57c0.7-1.71,1.66-3.19,2.88-4.44c1.22-1.24,2.65-2.22,4.29-2.92
			c1.64-0.7,3.4-1.05,5.27-1.05c1.85,0,3.59,0.35,5.21,1.05c1.63,0.7,3.05,1.68,4.25,2.92c1.21,1.25,2.16,2.72,2.85,4.44
			C743.99,341.26,744.33,343.11,744.33,345.11z M742.08,345.11c0-1.7-0.28-3.29-0.83-4.77c-0.55-1.48-1.33-2.76-2.31-3.85
			c-0.99-1.08-2.16-1.94-3.53-2.55c-1.37-0.62-2.87-0.92-4.49-0.92c-1.63,0-3.13,0.31-4.49,0.92c-1.37,0.62-2.55,1.47-3.53,2.55
			c-0.99,1.09-1.76,2.37-2.31,3.85c-0.55,1.48-0.83,3.07-0.83,4.77c0,1.7,0.28,3.29,0.83,4.75c0.55,1.47,1.33,2.74,2.31,3.83
			c0.99,1.09,2.16,1.94,3.53,2.55c1.37,0.62,2.87,0.92,4.49,0.92c1.63,0,3.13-0.31,4.49-0.92c1.37-0.62,2.55-1.47,3.53-2.55
			c0.99-1.08,1.76-2.36,2.31-3.83C741.8,348.4,742.08,346.81,742.08,345.11z'
                  ></path>
                  <path
                    className='st9'
                    d='M766.82,333.39v25.26h-2.11v-25.26h-9.21v-1.85h20.49v1.85H766.82z'
                  ></path>
                  <path
                    className='st9'
                    d='M797.96,358.65v-12.87h-15.57v12.87h-2.07v-27.11h2.07v12.39h15.57v-12.39h2.07v27.11H797.96z'
                  ></path>
                  <path
                    className='st9'
                    d='M806.47,358.65v-27.11h15.31v1.85h-13.24v10.58h11.95v1.85h-11.95v10.95h13.24v1.89H806.47z'
                  ></path>
                  <path
                    className='st9'
                    d='M851.22,339.97c0,1.36-0.24,2.6-0.72,3.74c-0.48,1.13-1.18,2.11-2.09,2.92c-0.91,0.81-2.02,1.45-3.33,1.9
			c-1.31,0.46-2.8,0.68-4.48,0.68c-0.49,0-1.05-0.02-1.68-0.07c-0.63-0.05-1.18-0.1-1.65-0.15v9.65h-2.07v-26.93
			c0.89-0.12,1.79-0.22,2.7-0.28c0.91-0.06,1.87-0.09,2.88-0.09c1.75,0,3.28,0.23,4.59,0.7c1.31,0.47,2.39,1.1,3.25,1.89
			c0.86,0.79,1.51,1.7,1.94,2.74C851,337.71,851.22,338.81,851.22,339.97z M849,340.08c0-0.91-0.17-1.78-0.52-2.61
			c-0.35-0.83-0.85-1.55-1.52-2.18c-0.67-0.63-1.5-1.13-2.5-1.52c-1-0.38-2.15-0.57-3.46-0.57c-0.64,0-1.28,0.02-1.92,0.07
			c-0.64,0.05-1.25,0.11-1.81,0.18v13.68c0.49,0.05,1.04,0.11,1.65,0.17c0.6,0.06,1.19,0.09,1.76,0.09c1.36,0,2.55-0.18,3.59-0.55
			c1.04-0.37,1.9-0.87,2.61-1.52c0.7-0.64,1.23-1.41,1.59-2.31C848.82,342.12,849,341.14,849,340.08z'
                  ></path>
                  <path
                    className='st9'
                    d='M875.22,358.65l-3.51-8.28h-12.72l-3.48,8.28h-2.18l11.87-27.55h0.37l11.87,27.55H875.22z M865.35,335.31
			l-5.59,13.28h11.17L865.35,335.31z'
                  ></path>
                  <path
                    className='st9'
                    d='M890.68,333.39v25.26h-2.11v-25.26h-9.21v-1.85h20.49v1.85H890.68z'
                  ></path>
                  <path className='st9' d='M904.33,358.65v-27.11h2.07v27.11H904.33z'></path>
                  <path
                    className='st9'
                    d='M912.99,358.65v-27.11h15.31v1.85h-13.24v10.58H927v1.85h-11.95v10.95h13.24v1.89H912.99z'
                  ></path>
                  <path
                    className='st9'
                    d='M953.89,359.06l-18.05-23.01v22.6h-2.03v-27.41l0.48-0.11l17.9,22.75v-22.34h2.07v27.44L953.89,359.06z'
                  ></path>
                  <path
                    className='st9'
                    d='M969.91,333.39v25.26h-2.11v-25.26h-9.21v-1.85h20.49v1.85H969.91z'
                  ></path>
                </g>
              </g>
              <image
                className='diamond1 diamond'
                width='199'
                height='295'
                href='/images/hero_background/diamond1.png'
                transform='matrix(1 0 0 1 1474.017 6.0121)'
              ></image>
            </svg>
          </div>
        </div>
      </div>
      <div className='container-fluid bg-light pt-3 responsive-p pb-3'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-6 col-lg-6 col-md-6 col-12 verlag-light'>
              <small className='verlag-bold'>THE PROBLEM</small>
              <h1 className='verlag-bold immy-blue '>Billions at risk of serious illness</h1>
              <p>
                The global burden of fungal infections continues to grow throughout the world. From
                community-acquired to hospital-associated to advanced HIV diseases, fungal
                infections are affecting more than 1 billion people globally, accounting for{' '}
                <b>1.5 million</b> deaths every year. (
                <a target='_blank' rel='noreferrer' href='https://gaffi.org'>
                  gaffi.org
                </a>
                )
              </p>
              <p>
                These infections need greater awareness among governments, health agencies and
                communities, but more importantly, they need to become more understood and
                recognizable among those physicians who are providing care to these patients.
              </p>
            </div>
            <div className='col-xl-6 col-lg-6 col-md-6 col-12 text-center d-flex align-items-center'>
              <img
                loading='lazy'
                className='img-fluid pt-3'
                alt='OVER 1 BILLION AFFLICTED'
                src='/images/immy/affected.png'
                style={{ maxHeight: '450px' }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='container-fluid responsive-p pt-3' style={{ minHeight: '20vh' }}>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-6 col-lg-6 col-md-6 col-12 text-center p-0 m-0 d-flex align-items-center'>
              <img
                loading='lazy'
                className='img-fluid'
                alt='ASP Product'
                src='/images/immy/asp_all_cropped.jpg'
                style={{ maxHeight: '450px' }}
              />
            </div>
            <div className='col-xl-6 col-lg-6 col-md-6 col-12 verlag-light pl-4'>
              <small className='verlag-bold'>THE SOLUTION</small>
              <h1 className='verlag-bold immy-blue '>World Class Diagnostics</h1>
              <p>
                Accurate diagnosis is key to proper patient care, especially with the continuing
                rise of improper or unnecessary treatment which can lead to antimicrobial
                resistance. Providing healthcare professionals a reliable and rapid test result is
                lifesaving for many patients.
              </p>
              <p>
                From single test, rapid lateral flow assays (LFA) to high-volume, enzyme
                immunoassays (EIA/ELISA), IMMY has developed affordable solutions that provide
                faster turn-around times with proven clinical accuracy.{' '}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='container-fluid bg-light pt-3 responsive-p pb-3'>
        <div className='container'>
          <div className='row'>
            <div className=' col-xl-6 col-lg-6 col-md-6 col-12 verlag-light'>
              <small className='verlag-bold'>THE IMPACT</small>
              <h1 className='verlag-bold immy-blue'>
                Bringing fungal diagnostics to a global audience{' '}
              </h1>
              <p>
                IMMY has been partnering with, and serving clinicians, laboratorians, and infectious
                disease physicians around the globe for more than 40 years. Our product solutions
                are being utilized in more than 80 countries to reduce the burden of fungal diseases
                and ultimately save lives.
              </p>
            </div>
            <div className='col-xl-6 col-lg-6 col-md-6 col-12 text-center d-flex align-items-center'>
              <div id='chartdiv'></div>
            </div>
          </div>
        </div>
      </div>

      <div
        className='modal fade'
        id='alertModal'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='exampleModalLabel'>
                IMMY's Services
              </h5>
              <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body'>
              <div className='row'>
                <div className='col-6 text-center'>
                  <a
                    className='navbar-brand  verlag-bold'
                    href='https://portal.immylabs.com'
                    style={{ color: '#002F87' }}
                  >
                    <img
                      loading='lazy'
                      src='/images/immylabs_logo.svg'
                      width='30'
                      height='30'
                      className='d-inline-block align-top'
                      alt='IMMYLABS'
                      style={{ color: '#002F87' }}
                    />
                    IMMYLabs
                  </a>
                  <p>For Covid-19 Services</p>
                  <a href='https://portal.immylabs.com'>
                    <button className='btn btn-outline-primary'>Click here</button>
                  </a>
                </div>
                <div className='col-6 text-center'>
                  <a className='navbar-brand  verlag-bold' href='/ ' style={{ color: '#002F87' }}>
                    <img
                      loading='lazy'
                      src='/images/immy_logo.svg'
                      width='30'
                      height='30'
                      className='d-inline-block align-top'
                      alt='IMMY'
                      style={{ color: '#002F87' }}
                    />
                    IMMY
                  </a>
                  <p>For Diagnostic Solutions</p>
                  <button
                    className='btn btn-outline-danger'
                    data-dismiss='modal'
                    aria-label='Close'
                  >
                    Click here
                  </button>
                </div>
              </div>
              {/* <!--                <p>-->
<!--                    IMMY is performing COVID-19 testing in collaboration with multiple state facilities in an effort to drastically reduce turnaround times and improve Oklahoma’s response to this pandemic. We are striving to support public health initiatives locally, while still providing life-saving diagnostic tests for other infectious diseases globally.-->
<!--                </p>-->
<!--                <p>Learn more about our efforts <a target="_blank" href="https://immylabs.com">here.</a></p>--> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
