import React from 'react';

function NotFound() {
  return (
    <div className='container-fluid pt-3'>
      <div className='container  verlag-bold pt-1'>
      <h2>404 Not Found</h2>
      <p>Sorry, the page you are looking for does not exist. Please click on the IMMY logo above to go to the home page.</p>
      </div>
    </div>
  );
}

export default NotFound;