import React from 'react';

function CTA_Relevancy() {
  return (
    <div>
      <div className='col-12 text-center pb-4'>
        <h1 className='verlag-bold'>
          Clinical Relevancy
          <sup>
            <small>2</small>
          </sup>
        </h1>
      </div>
      <div className='col-12  col-sm-12 offset-md-1 col-md-10 offset-lg-0 col-lg-12  pt-5'>
        <table
          className='table table-striped table-bordered table-info pb-0 mb-0 my-auto verlag-black  text-center  align-middle w-auto table-responsive border-top-0 border-left-0'
          style={{ borderLeftColor: 'white !important', borderTopColor: 'white !important' }}
        >
          <thead>
            <tr className='border-top-0 border-left-0'>
              <th
                scope='col'
                className='h2 align-middle table_header'
                style={{
                  backgroundColor: 'white !important',
                  borderTopColor: 'white !important',
                  borderLeftColor: 'white !important',
                }}
              >
                SERUM
              </th>
              <th scope='col' className='h5 align-middle' width='25%'>
                Positive % Agreement
              </th>
              <th scope='col' className='h5 align-middle' width='25%'>
                Negative % Agreement{' '}
              </th>
              <th scope='col' className='h5 align-middle' width='25%'>
                PPV
              </th>
              <th scope='col' className='h5 align-middle' width='25%'>
                NPV
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope='row' className='h5 align-middle'>
                Competitor EIA
              </th>
              <td className='h4 align-middle'>93%</td>
              <td className='h4 align-middle'>99%</td>
              <td className='h4 align-middle'>94%</td>
              <td className='h4 align-middle'>99%</td>
            </tr>
            <tr>
              <th scope='row' className='h5 align-middle'>
                Competitor EIA + ID
              </th>
              <td className='h4 align-middle'>92%</td>
              <td className='h4 align-middle'>99%</td>
              <td className='h4 align-middle'>94%</td>
              <td className='h4 align-middle'>99%</td>
            </tr>
          </tbody>
        </table>
        <small className='text-left'>
          2) Contreras DA, Li Q, Garner OB. Evaluation of Sona Lateral Flow Assay for the Rapid
          Detection of Coccidioides Immitis. Poster session presented at: American Society for
          Microbiology Microbe. 2019 June 20-24; San Francisco, CA.{' '}
        </small>
      </div>
      <div className='col-12 verlag-light pt-2'>
        <p>
          When tested against a competitor’s EIA and ID results, the CTA LFA showed high diagnostic
          performance in 908 serum specimens.
        </p>
        <p>
          The CTA LFA agreed with 93% of the competitor’s EIA positive test results, and 99% of
          their negative test results. When compared to both the competitor’s EIA and ID results,
          the CTA LFA agreed with 92% of their positive results and 99% of their negative results.
          <sup>2</sup>{' '}
        </p>
      </div>
      <div className='col-12 text-center verlag-bold'>
        <h1>Procedure</h1>
      </div>
      <div className='col-12  col-sm-12 offset-md-1 col-md-10 text-center embed-responsive embed-responsive-16by9'>
        <iframe
          className='embed-responsive-item'
          src='https://www.youtube.com/embed/KL-9MRIFml0'
          allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
          allowfullscreen
        ></iframe>
      </div>
    </div>
  );
}

export default CTA_Relevancy;
