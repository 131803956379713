/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import DocumentTable from './DocumentTable';
import axios from 'axios';

function Id_Kits(props) {
  let [docs, setDocs] = useState();

  useEffect(() => {
    axios
    .post('/etqdocs', {
      id: props.etq,
      headers: {
        'Content-Type': ' text/htmL',
      },
    })
    .then((response) => {
      //console.log(response.data)
      setDocs(response.data);
    })
    .catch((e) => {
      console.error(e);
    });
  }, []);

  return (
    <div>
      {/* <!-- Page Content -->
<style>
    .container p{
        font-size: 1.3rem;
    }
    .id_red{
        color: #c41230;
    }
</style> */}
      <div className='container-fluid  pt-3'>
        <div className='container'>
          {/* <!-- Portfolio Item Heading --> */}
          <div className='row'>
            <div className=' my-auto  col-12 offset-sm-2 col-sm-10 offset-md-1 col-md-10 offset-lg-3 col-lg-8'>
              <h1 className='verlag-black my-0 display-4 text-capitalize font-italic '>
                IMMUNODIFFUSION
              </h1>
              <h1
                className='verlag-light_italic my-0 display-4'
                style={{ marginTop: '-10px !important', color: 'grey' }}
              >
                KITS AND REAGENTS
              </h1>
            </div>
          </div>

          <hr style={{ width: '75%' }} className='mx-auto' />

          {/* <!-- Portfolio Item Row --> */}
          <div className='row'>
            <div className='col-12'>
              <h1 className='verlag-black my-0 text-capitalize id_red'>
                ID Fungal Antibody System - ID1001
              </h1>
              <p>
                <b>
                  <i>Aspergillus, Blastomyces, Coccidioides, and Histoplasma</i>
                </b>
              </p>
              <p className='verlag-light'>
                The Immunodiffusion Fungal Antibody test kit contains enough reagents (antigen,
                positive control, and plates) to test 24 specimens for antibodies against each of
                the following organisms
                <i>Aspergillus, Blastomyces, Coccidioides,</i> and <i>Histoplasma.</i>
              </p>
            </div>

            <div className='col-sm-12 col-md-12 col-lg-7 mb-2'>
              <img className='img-fluid' src='/images/immy/ID1001.jpg' alt='' />
            </div>

            <div className='col-sm-12 col-md-12 offset-lg-0 col-lg-5 verlag-light mb-2'>
              <h2 className=' verlag-bold my-0'>Kit Contents:</h2>

              <table
                className='table table-sm table-borderless table-responsive'
                style={{ fontSize: '1.1rem', width: '100%' }}
              >
                <thead>
                  <tr>
                    <th scope='col' width='75%'></th>
                    <th scope='col' width='25%'></th>
                  </tr>
                </thead>
                <tbody className='verlag-bold'>
                  <tr className='pt-0'>
                    <td>
                      <i>Aspergillus</i> ID Antigen
                    </td>
                    <td>200 μL</td>
                  </tr>
                  <tr className='pt-0'>
                    <td>
                      <i>Aspergillus</i> ID Positive Control
                    </td>
                    <td>400 μL</td>
                  </tr>
                  <tr>
                    <td>
                      <i>Blastomyces</i> ID Antigen
                    </td>
                    <td>200 μL</td>
                  </tr>
                  <tr>
                    <td>
                      <i>Blastomyces</i> ID Positive Control
                    </td>
                    <td>400 μL</td>
                  </tr>
                  <tr>
                    <td>
                      <i>Coccidioides</i> IDCF Antigen
                    </td>
                    <td>200 μL</td>
                  </tr>
                  <tr>
                    <td>
                      <i>Coccidioides</i> IDCF Positive Control
                    </td>
                    <td>400 μL</td>
                  </tr>{' '}
                  <tr>
                    <td>
                      <i>Histoplasma</i> ID Antigen
                    </td>
                    <td>200 μL</td>
                  </tr>
                  <tr>
                    <td>
                      <i>Histoplasma</i> ID Positive Control
                    </td>
                    <td>400 μL</td>
                  </tr>{' '}
                  <tr>
                    <td>Cleargel™ 4 Series Plate</td>
                    <td>6 plates (ID1019)</td>
                  </tr>
                </tbody>
              </table>

              <div className='text-center verlag-black'>
                <button
                  type='button'
                  className='btn btn-outline-info btn-block'
                  onClick={() => window.location.href ='/contact'}
                >
                  LEARN MORE
                </button>
              </div>
            </div>
            <hr style={{ width: '75%' }} className='mx-auto' />
            <div className='col-12 pt-3'>
              <h2 className='verlag-black my-0 display-6 text-capitalize id_red'>
                ID-Candida Antibody System - CA 1001
              </h2>
            </div>
            <div className='col-sm-12 col-md-12  col-lg-12'>
              <p className='verlag-light'>
                The ID Candida antibody test kit contains enough reagents (antigen, positive
                control, and plates) to test 40 specimens for antibodies against <i>Candida</i>.
              </p>
            </div>
            <div className='col-12'>
              <h3 className=' verlag-bold my-0'>Kit Contents:</h3>
              <table
                className='table table-sm table-borderless table-responsive'
                style={{ fontSize: '1.1rem' }}
              >
                <thead>
                  <tr>
                    <th scope='col'></th>
                    <th scope='col'></th>
                  </tr>
                </thead>
                <tbody className='verlag-bold'>
                  <tr className='pt-0'>
                    <td>
                      <i>Candida</i> ID Antigen
                    </td>
                    <td>250 μL</td>
                  </tr>
                  <tr className='pt-0'>
                    <td>
                      <i>Candida</i> ID Positive Control
                    </td>
                    <td>500 μL</td>
                  </tr>
                  <tr>
                    <td>Cleargel™ Single Series</td>
                    <td>10 plates (CA1019)</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <hr style={{ width: '75%' }} className='mx-auto' />
      <div className='container-fluid  pt-3'>
        <div className='container'>
          <div className='row'>
            <div className="col-12 text-center pb-4">
              {/*  <h1 className='verlag-black'>Cleargel™ Plates</h1>*/}
              <img className="img-fluid" src="/images/Cleargel_Plates.png" alt="" width="300" height="300" />
            </div>

            <div className="col-sm-12 col-md-12  col-lg-12 verlag-light">
              <p>
                In order to overcome the inherently opaque appearance of agarose immunodiffusion
                plates, IMMY developed Cleargel™.
              </p>
              <p>
                Cleargel™ is a proprietary gelling agent which produces an ultra-clear gel structure
                for easy visualization of precipitin bands from most species except rabbit.
              </p>
              <table
                className='table table-sm table-borderless table-responsive'
                style={{ fontSize: '1.1rem' }}
              >
                <thead></thead>
                <tbody>
                  <tr className='pt-0'>
                    <td className='verlag-bold'>
                      Cleargel™ 4 Series{' '}
                      <p className='pl-3 verlag-light'>
                        <small>
                          <i>approximately 100 patient determinations</i>
                          <p>6 / Pack</p>
                          <p>ID1019</p>
                          <img className='img-fluid' src='/images/ID1019Plate.jpg' alt='' width='250' height='250' />
                        </small>
                      </p>
                    </td>
                    <td className='verlag-bold'>
                      Cleargel™ 4 Series - Large Well
                      <p className='pl-3 verlag-light'>
                        <small>
                          {' '}
                          <i>approximately 100 patient determinations</i>
                          <p>6 / Pack</p>
                          <p>ID1039</p>
                          <img className='img-fluid' src='/images/ID1039Plate.jpg' alt='' width='250' height='250' />
                        </small>
                      </p>
                    </td>
                    <td className='verlag-bold'>
                      Cleargel™ Single Series{' '}
                      <p className='pl-3 verlag-light'>
                        <small>
                          {' '}
                          <i>approximately 40 patient determinations</i>
                          <p>10 / Pack</p>
                          <p>CA1019</p>
                          <img className='img-fluid' src='/images/CA1019Plate.jpg' alt='' width='250' height='250' />
                        </small>
                      </p>
                    </td>
                    <td className='verlag-bold'>
                      Agarose 4 Series{' '}
                      <p className='pl-3 verlag-light'>
                        <small>
                          {' '}
                          <i>approximately 100 patient determinations</i>
                          <p>6 / Pack</p>
                          <p>ID1029</p>
                          <img className='img-fluid' src='/images/ID1029Plate.jpg' alt='' width='250' height='250' />
                        </small>
                      </p>
                    </td>
                  {/*  <td>6 / Pack</td>
                    <td>ID1019</td>*/}
                    {/*<td><img className='img-fluid' src='/images/ID1019Plate.jpg' alt='' /></td>*/}
                  </tr>
             {/*     <tr className='pt-0'>
                    <td className='verlag-bold'>
                      Cleargel™ 4 Series - Large Well
                      <p className='pl-3 verlag-light'>
                        <small>
                          {' '}
                          <i>approximately 100 patient determinations</i>
                        </small>
                      </p>
                    </td>
                    <td>6 / Pack</td>
                    <td>ID1039</td>
                  </tr>*/}
              {/*    <tr className='pt-0'>
                    <td className='verlag-bold'>
                      Cleargel™ Single Series{' '}
                      <p className='pl-3 verlag-light'>
                        <small>
                          {' '}
                          <i>approximately 40 patient determinations</i>
                        </small>
                      </p>
                    </td>
                    <td>10 / Pack</td>
                    <td>CA1019</td>
                  </tr>*/}
                </tbody>
              </table>
            </div>
     {/*       <div className='col-sm-12 col-md-12  col-lg-6'>
              <img className='img-fluid' src='/images/immy/13964-053.jpg' alt='' />
            </div>*/}

{/*            <div className='col-12'>
              <h2 className='verlag-black my-0 display-6 text-capitalize'>
                Immunodiffusion Plates
              </h2>
            </div>
            <div className='col-12'>
              <table
                className='table table-sm table-borderless table-responsive'
                style={{ fontSize: '1.1rem' }}
              >
                <thead></thead>
                <tbody className='verlag-bold'>
                  <tr className='pt-0'>
                    <td>
                      Agarose 4 Series{' '}
                      <p className='pl-3 verlag-light'>
                        <small>
                          {' '}
                          <i>approximately 40 patient determinations</i>
                        </small>
                      </p>
                    </td>
                    <td>6 / Pack</td>
                    <td>ID1029</td>
                  </tr>
                </tbody>
              </table>
            </div>*/}
          </div>
        </div>
      </div>
      <hr style={{ width: '75%' }} className='mx-auto' />
      <div className='container-fluid  pt-3'>
        <div className='container'>
          <div className='row verlag-bold'>
            <div className='col-12 text-center pb-4'>
              <h1 className='verlag-black'>ID REAGENTS</h1>
            </div>
            <div className='col-12 col-md-12 col-lg-6'>
              <h3 className='font-italic id_red'>Aspergillus</h3>
              <p className='verlag-light' style={{ fontSize: '1.1rem' }}>
                The reagents listed below enable labs to test for antibodies against the most common
                species of
                <i>Aspergillus</i> (A. <i>fumigatus</i>, A. <i>flavus</i>, A. <i>niger</i>, and A.{' '}
                <i>terreus</i>), and the first two reagents listed (A30110 and A40110) can be used
                to test for all of the species at once.
              </p>

              <table
                className='table table-sm table-borderless table-responsive'
                style={{ fontSize: '1.1rem' }}
              >
                <thead style={{ width: '100%' }}>
                  <tr>
                    <th scope='col' width='50%'></th>
                    <th scope='col' width='50%'></th>
                    <th scope='col' width='50%'></th>
                  </tr>
                </thead>
                <tbody className='verlag-bold'>
                  <tr>
                    <td>
                      <i>Aspergillus</i> ID Antigen
                    </td>
                    <td className='text-center'>1 mL</td>
                    <td>A30110</td>
                  </tr>
                  <tr>
                    <td>
                      <i>Aspergillus</i> ID Positive Control
                    </td>
                    <td className='text-center'>1 mL</td>
                    <td>A40110</td>
                  </tr>
                  <tr>
                    <td>
                      <i>A. fumigatus</i> ID Antigen
                    </td>
                    <td className='text-center'>1 mL</td>
                    <td>A70110</td>
                  </tr>
                  <tr>
                    <td>
                      <i>A. fumigatus</i> ID Positive Control
                    </td>
                    <td className='text-center'>1 mL</td>
                    <td>A80110</td>
                  </tr>
                  <tr>
                    <td>
                      <i>A. flavus</i> ID Antigen
                    </td>
                    <td className='text-center'>1 mL</td>
                    <td>A90110</td>
                  </tr>
                  <tr>
                    <td>
                      <i>A. flavus</i> ID Positive Control
                    </td>
                    <td className='text-center'>1 mL</td>
                    <td>AA0110</td>
                  </tr>
                  <tr>
                    <td>
                      <i>A. niger</i> ID Antigen
                    </td>
                    <td className='text-center'>1 mL</td>
                    <td>AD0110</td>
                  </tr>
                  <tr>
                    <td>
                      <i>A. niger</i> ID Positive Control
                    </td>
                    <td className='text-center'>1 mL</td>
                    <td>AE0110</td>
                  </tr>
                  <tr>
                    <td>
                      <i>A. terreus</i> ID Antigen
                    </td>
                    <td className='text-center'>1 mL</td>
                    <td>AF0110</td>
                  </tr>
                  <tr>
                    <td>
                      <i>A. terreus</i> ID Positive Control
                    </td>
                    <td className='text-center'>1 mL</td>
                    <td>AG0110</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='col-12 col-md-12 col-lg-6'>
              <h3 className='font-italic id_red'>Farmer's Lung</h3>
              <p>
                <b>
                  <i>(Hypersensitivity Pneumonitis) ID Panel</i>
                </b>
              </p>
              <table
                className='table table-sm table-borderless table-responsive'
                style={{ fontSize: '1.1rem' }}
              >
                <thead style={{ width: '100%' }}>
                  <tr>
                    <th scope='col' width='50%'></th>
                    <th scope='col' width='50%'></th>
                    <th scope='col' width='50%'></th>
                  </tr>
                </thead>
                <tbody className='verlag-bold'>
                  <tr>
                    <td>Pigeon Serum Antigen</td>
                    <td className='text-center'>1 mL</td>
                    <td>PS10110</td>
                  </tr>
                  <tr>
                    <td>Goat Anti-Pigeon Serum</td>
                    <td className='text-center'>1 mL</td>
                    <td>PS20110</td>
                  </tr>
                  <tr>
                    <td>
                      <i>M. faeni</i> ID Antigen
                    </td>
                    <td className='text-center'>1 mL</td>
                    <td>SR10110</td>
                  </tr>
                  <tr>
                    <td>
                      Goat Anti-<i>M. faeni</i> Serum
                    </td>
                    <td className='text-center'>1 mL</td>
                    <td>SR20110</td>
                  </tr>
                  <tr>
                    <td>
                      <i>S. viridis</i> ID Antigen
                    </td>
                    <td className='text-center'>1 mL</td>
                    <td>SV10110</td>
                  </tr>
                  <tr>
                    <td>
                      Goat Anti-<i>S. viridis</i> Serum
                    </td>
                    <td className='text-center'>1 mL</td>
                    <td>SV20110</td>
                  </tr>
                  <tr>
                    <td>
                      <i>T. candidus</i> ID Antigen
                    </td>
                    <td className='text-center'>1 mL</td>
                    <td>TC10110</td>
                  </tr>
                  <tr>
                    <td>
                      Goat Anti-<i>T. candidus</i> Serum
                    </td>
                    <td className='text-center'>1 mL</td>
                    <td>TC20110</td>
                  </tr>
                  <tr>
                    <td>
                      <i>T. vulgaris</i> ID Antigen
                    </td>
                    <td className='text-center'>1 mL</td>
                    <td>TV10110</td>
                  </tr>
                  <tr>
                    <td>
                      Goat Anti-<i>T. vulgaris</i> Serum
                    </td>
                    <td className='text-center'>1 mL</td>
                    <td>TV20110</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className='col-12 col-md-12 col-lg-6'>
              <h3 className='font-italic id_red'>Histoplasma</h3>
              <table
                className='table table-sm table-borderless table-responsive'
                style={{ fontSize: '1.1rem' }}
              >
                <thead style={{ width: '100%' }}>
                  <tr>
                    <th scope='col' width='50%'></th>
                    <th scope='col' width='50%'></th>
                    <th scope='col' width='50%'></th>
                  </tr>
                </thead>
                <tbody className='verlag-bold'>
                  <tr>
                    <td>
                      <i>Histoplasma</i> ID Antigen
                    </td>
                    <td className='text-center'>1 mL</td>
                    <td>H50110</td>
                  </tr>
                  <tr>
                    <td>
                      <i>Histoplasma</i> ID Positive Control
                    </td>
                    <td className='text-center'>1 mL</td>
                    <td>H60110</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='col-12 col-md-12 col-lg-6'>
              <h3 className='font-italic id_red'>Blastomyces</h3>
              <table
                className='table table-sm table-borderless table-responsive'
                style={{ fontSize: '1.1rem' }}
              >
                <thead style={{ width: '100%' }}>
                  <tr>
                    <th scope='col' width='50%'></th>
                    <th scope='col' width='50%'></th>
                    <th scope='col' width='50%'></th>
                  </tr>
                </thead>
                <tbody className='verlag-bold'>
                  <tr>
                    <td>
                      <i>Blastomyces</i> ID Antigen
                    </td>
                    <td className='text-center'>1 mL</td>
                    <td>B30110</td>
                  </tr>
                  <tr>
                    <td>
                      <i>Blastomyces</i> ID Positive Control
                    </td>
                    <td className='text-center'>1 mL</td>
                    <td>B40110</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='col-12 col-md-12 col-lg-6'>
              <h3 className='font-italic id_red'>Coccidioides</h3>
              <p className='verlag-light' style={{ fontSize: '1.1rem' }}>
                The IDCF reagents typically generate a response against lgG antibodies, and the IDTP
                reagents typically generate a response against lgM antibodies.
              </p>

              <table
                className='table table-sm table-borderless table-responsive'
                style={{ fontSize: '1.1rem' }}
              >
                <thead style={{ width: '100%' }}>
                  <tr>
                    <th scope='col' width='50%'></th>
                    <th scope='col' width='50%'></th>
                    <th scope='col' width='50%'></th>
                  </tr>
                </thead>
                <tbody className='verlag-bold'>
                  <tr>
                    <td>
                      <i>Coccidioides</i> IDCF Antigen
                    </td>
                    <td className='text-center'>1 mL</td>
                    <td>C30110</td>
                  </tr>
                  <tr>
                    <td>
                      <i>Coccidioides</i> IDCF Positive Control
                    </td>
                    <td className='text-center'>1 mL</td>
                    <td>C40110</td>
                  </tr>
                  <tr>
                    <td>
                      <i>Coccidioides</i> IDTP Antigen
                    </td>
                    <td className='text-center'>1 mL</td>
                    <td>C70110</td>
                  </tr>
                  <tr>
                    <td>
                      <i>Coccidioides</i> IDTP Positive Control
                    </td>
                    <td className='text-center'>1 mL</td>
                    <td>CD0110</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='col-12 col-md-12 col-lg-6'>
              <h3 className='font-italic id_red'>Candida</h3>
              <table
                className='table table-sm table-borderless table-responsive'
                style={{ fontSize: '1.1rem' }}
              >
                <thead style={{ width: '100%' }}>
                  <tr>
                    <th scope='col' width='50%'></th>
                    <th scope='col' width='50%'></th>
                    <th scope='col' width='50%'></th>
                  </tr>
                </thead>
                <tbody className='verlag-bold'>
                  <tr>
                    <td>
                      <i>Candida</i> ID Antigen
                    </td>
                    <td className='text-center'>1 mL</td>
                    <td>C50110</td>
                  </tr>
                  <tr>
                    <td>
                      <i>Candida</i> ID Positive Control
                    </td>
                    <td className='text-center'>1 mL</td>
                    <td>C60110</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <p className='verlag-bold'>
            1 vial of antigen = approximately 130 patient determinations <br />2 vials of positive
            control = approximately 130 patient determinations
          </p>
        </div>
      </div>

      <div className='container mt-5 mb-5'>
        <div className='row'>
          <div className='col-12  col-sm-12 offset-md-1 col-md-10 offset-lg-2 col-lg-8'>
            <Tabs className='nav nav-tabs verlag-black border-bottom' defaultActiveKey='docs'>
              <Tab className='border' eventKey='docs' title='DOCUMENTS'>
                <div className='tab-pane' id='DOCUMENTS' style={{ minHeight: '250px' }}>
                  {docs ? <DocumentTable documents={docs} /> : <></>}
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Id_Kits;
