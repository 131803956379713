import React from 'react';

function Cab_Relevancy() {
  return (
    <div>
      <div className='col-12 text-center pb-4'>
        <h1 className='verlag-bold'>
          Clinical Relevancy
          <sup>
            <small>2</small>
          </sup>
        </h1>
      </div>
      <div className='col-12 col-sm-12 col-md-9 col-lg-7 col-xl-6 mx-auto d-flex justify-content-center'>
        <div className='d-inline-block overflow-x-auto'>
          <table
            className='table table-striped table-responsive table-bordered table-info pb-0 mb-0 my-auto verlag-black text-center align-middle w-auto border-top-0 border-left-0'
            style={{ borderLeftColor: 'white !important', borderTopColor: 'white !important' }}
          >
            <thead>
              <tr className='border-top-0'>
                <th
                  scope='col'
                  className='h2 align-middle table_header'
                  style={{
                    backgroundColor: 'white !important',
                    borderTopColor: 'white !important',
                    borderLeftColor: 'white !important',
                  }}
                >
                  Serum
                </th>
                <th scope='col' className='h5 align-middle'>
                  Sensitivity
                </th>
                <th scope='col' className='h5 align-middle'>
                  Specificity{' '}
                </th>
                <th scope='col' className='h5 align-middle'>
                  PPV{' '}
                </th>
                <th scope='col' className='h5 align-middle'>
                  NPV{' '}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope='row' className='h5 align-middle'>
                  CF/ID
                </th>
                <td className='h4 align-middle'>98%</td>
                <td className='h4 align-middle'>99%</td>
                <td className='h4 align-middle'>96%</td>
                <td className='h4 align-middle'>95.5%</td>
              </tr>
            </tbody>
          </table>
          <small className='text-left'>2.IMMY CAB102 IFU</small>
        </div>
      </div>
    </div>
  );
}

export default Cab_Relevancy;
