import React from 'react';

function Careers() {
  return (
    <div className='container-fluid pt-3'>
      <div className='container  verlag-bold pt-1'>
        <h1 className='text-center'>Why IMMY?</h1>
        <p className='verlag-light'>
          Founded in 1979, IMMY is a rapidly growing in-vitro diagnostic manufacturer that is the
          global leader for fungal diseases. IMMY’s products are sold in over 80 countries and they
          are used to bridge the gap between sick patients and the therapy they need. By providing
          physicians with accurate, timely diagnoses, IMMY’s products save lives.
        </p>
        <p>
          We are passionate about creating tests that ultimately give patients a chance for a
          healthier life. Behind everything that we produce and ship out, there is a face, a story,
          a family, a heritage. And each day, our employees have a chance to impact that.
        </p>
        <div className='row pt-3 align-items-center'>
          <div className='col-8'>
            <img className='img-fluid p-2 black-outline' src='/images/immy-group.jpg' />
          </div>
          <div className='col-4'>
            <img
              className='img-fluid pt-2 black-outline'
              src='/images/file-11.jpeg'
              style={{ backgroundSize: 'cover' }}
            />
            <img className='img-fluid pt-4 black-outline' src='/images/IMG_5900.png' />
          </div>
        </div>
      </div>
      <div className='container pt-5'>
        <div className='row verlag-light  text-center'>
          <div className='col-12  text-center pb-4'>
            <h1 className='text-le verlag-black'>WHO WE ARE</h1>
            <p className='text-left'>
              All of our employees bring something different to the table and make IMMY a diverse,
              unique, and fun place to be. While individually we are vastly different, together we
              have built the foundation of our company on the shared values of{' '}
              <b>humble, driven, and collaborative. </b>
            </p>
          </div>
          <div className='col-xl-4 text-center'>
            <img
              className='img-fluid text-center'
              src='images/icons/humble.png'
              style={{ maxHeight: '100px' }}
            />
            <h1 className='verlag-black'>Humble</h1>
            <p className='text-left'>
              We embrace feedback and recognize areas for growth. We hold ourselves accountable, learning from mistakes to foster continuous improvement.
              We express gratitude and prioritize collective success over individual recognition.
              Our humility is reflected in treating everyone with dignity, creating an environment of mutual respect and learning.
            </p>
          </div>
          <div className='col-xl-4'>
            <img
              className='img-fluid text-center'
              src='images/icons/driven.png'
              style={{ maxHeight: '100px' }}
            />
            <h1 className='verlag-black'>Driven</h1>
            <p className='text-left'>
              Motivated by a growth mindset, we proactively tackle challenges and seek innovative solutions.
              We are unafraid to challenge the status quo and envision a better future.
              With a strong work ethic, persistence, and resilience, we operate with urgency, propelling us toward excellence in everything we do.
            </p>
          </div>
          <div className='col-xl-4'>
            <img
              className='img-fluid text-center'
              src='images/icons/collaborative.png'
              style={{ maxHeight: '100px' }}
            />
            <h1 className='verlag-black'>Collaborative</h1>
            <p className='text-left'>
              We actively engage in group discussions, share expertise, and value diverse perspectives to make informed decisions.
              We foster trust, build positive relationships, encourage growth, and remain committed to shared goals with a positive mindset.
              Collaboration drives us to overcome challenges together.
            </p>
          </div>
          <div className='col-12 pt-7 pb-2 text-center pb-4'>
            <h1 className='text-le verlag-black'>BENEFITS</h1>
            <hr
              style={{
                height: '2px',
                borderWidth: 0,
                color: 'gray',
                backgroundColor: 'gray',
                width: '25%',
                marginInlineEnd: 'auto',
                marginInlineStart: 'auto',
              }}
            />
          </div>
          <div className='col-xl-6'>
            <h2 className='text-le verlag-black'>Health</h2>
            <p className='text-left pb-4'>
              We offer two competitive health plans through BCBS to fit employees&apos; specific
              needs. Our HSA/FSA allows for flexible spending for medical expenses.
            </p>
            <h2 className='verlag-black'>Wellness</h2>
            <p className='text-left pb-4'>
              Focus on your physical health with our gym reimbursement plan. We promote a healthy
              work-life balance with 3 weeks paid time off after 90 days (additional time accrued
              yearly!).
            </p>
            <h2 className='verlag-black'>Family</h2>
            <p className='text-left'>
              We provide 4 weeks of paid medical maternity leave, along with 2 weeks of parental
              leave, and optional short-term disability. In addition, employees have the option to
              elect into our Dependent Care FSA. Our family culture has earned us an Oklahoma Family
              Positive Workplace certification!
            </p>
          </div>
          <div className='col-xl-6'>
            <h2 className='verlag-black'>Future</h2>
            <p className='text-left'>
              Plan for the future with IMMY’s 401K Safe Harbor plan. We also provide tuition
              reimbursement for eligible employees continuing their education.
            </p>
            <h2 className='verlag-black'>Fun</h2>
            <p className='text-left'>
              We provide a fun atmosphere for all our employees with holiday celebrations, potlucks,
              and monthly Pancakes & Presentations (with topics ranging from estate planning to beer
              brewing). We celebrate every fiscal year end with a company-wide party. IMMY also has
              OKC Dodgers season tickets that are raffled off to employees.
            </p>
            <h2 className='verlag-black'>Comfort</h2>
            <p className='text-left'>
              We encourage employees to be comfortable at work with our casual dress code. You’ll
              often see our employees rocking their awesome IMMY-branded gear from our on-site IMMY
              shop.
            </p>
          </div>
          <div className='col-12  text-center pb-5 pt-7'>
            <a
              href='https://recruiting.paylocity.com/Recruiting/Jobs/All/081bc514-e4d3-48cd-aea5-5e834b26f69d'
              rel='noreferrer'
              target='_blank'
            >
              <button className='btn btn-info' style={{ height: '100px', width: '250px' }}>
                <h3>Apply Now!</h3>
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Careers;
