import React from 'react';

function About() {
  return (
    <div className='container-fluid mt-1 pt-1 pb-4' style={{ backgroundColor: '#F5F5F5' }}>
      <div className='row'>
        <div className='col-12 verlag-bold text-center mt-3'>
          <h1>Our Mission</h1>
        </div>
        <div className='col-12 text-center'>
          <h6>saving lives one diagnostic at a time</h6>
        </div>
      </div>
      <div className='container mt-4 pt-3 responsive-p ' style={{ backgroundColor: 'white' }}>
        <div className='row d-flex align-items-center'>
          <div className='col-12 verlag-bold text-center'>
            <h1>About IMMY</h1>
          </div>
          <div className='col-xl-6  verlag-light'>
            <small className='verlag-bold'>40 YEARS OF DEVELOPMENT</small>
            <p>
              IMMY manufactures, markets, and distributes innovative lines of diagnostic tests and
              reagents for infectious diseases.
            </p>
            <p>
              Founded in 1979, IMMY has evolved from a company that filled a need in the market to
              an organization with a <b>global focus</b> on saving lives through the production of
              affordable diagnostics.
            </p>
          </div>

          <div className='col-xl-6 text-center'>
            <img
              className='img-fluid '
              src='/images/colorized_logo.png'
              style={{ maxHeight: '300px' }}
            />
          </div>

          <div className='col-xl-6 text-center'>
            <img
              className='img-fluid'
              src='/images/Joy_victor.jpg'
              style={{ maxHeight: '400px' }}
            />
          </div>
          <div className='col-xl-6  verlag-light   '>
            <small className='verlag-bold '>BRIDGING THE GAP</small>
            <p className='align-items-center'>
              We take pride in producing reliable, accurate diagnostics, that bridge the gap between
              patients and the treatment they desperately need. Our goal is to make a{' '}
              <b>monumental impact</b> on the world by bringing crucial diagnostics closer to the
              patient.
            </p>
          </div>
          <div className='col-xl-6  verlag-light mt-2'>
            <small className='verlag-bold'>GOLD STANDARD DIAGNOSTICS</small>
            <p>
              With products for cryptococcus, aspergillus, histoplasma, coccidioides, and
              blastomyces, along with mycobacteria specimen preparation reagents, IMMY is setting
              the standard with <b> accurate and affordable</b> diagnostics for infectious diseases.{' '}
            </p>
          </div>

          <div className='col-xl-6'>
            <img
              className='img-fluid '
              src='/images/haydnpipet.jpg'
              style={{ maxHeight: '450px' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
