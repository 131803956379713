/* eslint-disable no-undef */
import React, { useState } from 'react';
import ContactModal from './ContactModal';

function Nav() {

  let [show, setShow] = useState();

  return (
    <div>
      <div>
        <p className='verlag-light' style={{ fontStyle: 'italic', backgroundColor: 'mistyrose', textAlign: 'center' }}>
          IMMY expands its diagnostic capabilities with the acquisition of OLM Diagnostics and their cutting-edge
          molecular diagnostics portfolio. Follow along for more updates as we integrate these products to better serve
          our valued customers.
        </p>
      </div>
      {/* <ContactModal show={show} setNavShowFalse={() => {setShow(0)}}/>*/}
      <div className='container-fluid p-0' style={{ boxShadow: '0 2px 4px rgba(0,0,0,.2)' }}>
        <div className='container'>
          <nav className='navbar navbar-expand-lg navbar-light '>
            <a className='navbar-brand verlag-bold' href='/ ' style={{ color: '#002F87' }}>
              <img
                src='/images/immy_logo.svg'
                width='30'
                height='30'
                className='d-inline-block align-top'
                alt=''
                style={{ color: '#002F87' }}
              />
              IMMY
            </a>
            <button
              className='navbar-toggler'
              type='button'
              data-bs-toggle='collapse'
              data-bs-target='#navbarText'
              aria-controls='navbarText'
              aria-expanded='false'
              aria-label='Toggle navigation'
            >
              <span className='navbar-toggler-icon'></span>
            </button>
            <div className='collapse navbar-collapse' id='navbarText'>
              <ul className='navbar-nav mr-auto immy-blue'>
                <li className='nav-item pl-5'></li>
                <li className='nav-item dropdown '>
                  <a
                    className='nav-link dropdown-toggle'
                    href='#'
                    id='navbarDropdown'
                    style={{ color: '#002F87' }}
                    role='button'
                    data-bs-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    PRODUCTS
                  </a>
                  <ul className='dropdown-menu multi-column product-dropdown'>
                    <div className="row">
                      <div className="col-sm-4">
                        <ul className="multi-column-dropdown">
                          <li className="">
                            <a className="dropdown-item-text ">
                              <b> LATERAL FLOW ASSAYS </b>
                            </a>
                          </li>
                          <li className="dropdown-divider"></li>
                          <li>
                            <a href="aspergillus-gm-lateral-flow-assay-fungal-test">
                              <i>Aspergillus</i> Galactomannan
                            </a>
                          </li>
                          <li>
                            <a href="crag-cryptococcal-antigen-lateral-flow-assay-fungal-test">
                              <i>Cryptococcal</i> Antigen (CrAg LFA)
                            </a>
                          </li>
                          {/*  <li>
                             <a href='cragsq'>
                              <i>Cryptococcal</i> Antigen Semi-Quantitative (CrAg SQ LFA)
                            </a>
  </li>*/}
                          <li>
                            <a href="coccidioides-antibody-lateral-flow-assay-fungal-test">
                              <i>Coccidioides</i> Antibody
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="col-sm-4">
                        <ul className="multi-column-dropdown">
                          <li>
                            <a className="dropdown-item-text ">
                              <b>ENZYME IMMUNOASSAY</b>
                            </a>
                          </li>
                          <li className="dropdown-divider"></li>
                          <li>
                            <a href="histoplasma-gm-enzyme-immunoassay-fungal-test">
                              <i>Histoplasma</i> Galactomannan
                            </a>
                          </li>
                          <li>
                            <a href="cryptococcal-antigen-enzyme-immunoassay-fungal-test">
                              <i>Cryptococcal</i> Antigen
                            </a>
                          </li>
                          <li>
                            <a href="coccidioides-ab-enzyme-immunoassay-fungal-test">
                              <i>Coccidioides</i> Antibody
                            </a>
                          </li>
                          <li>
                            <a href="blastomyces-ab-enzyme-immunoassay-fungal-test">
                              <i>Blastomyces</i> Antibody
                            </a>
                          </li>
                       {/*   <li>
                            <a href="aspergillus-gm-enzyme-immunoassay-fungal-test">
                              <i>Aspergillus</i> Galactomannan{' '}
                            </a>
                          </li>*/}
                          {/* <!--                                        <li><a href="covid">SARS-CoV-2 Total Antibody</a></li>--> */}
                        </ul>
                      </div>
                      <div className="col-sm-4">
                        <ul className="multi-column-dropdown">
                          <li>
                            <a className="dropdown-item-text ">
                              <b>OTHER PRODUCTS</b>
                            </a>
                          </li>
                          <li className="dropdown-divider"></li>
                          <li>
                            <a href="latex-agglutination">Latex Agglutination</a>
                          </li>
                          <li>
                            <a href="mycoddr-AFB-processing">MycoDDR</a>
                          </li>
                          <li>
                            <a href="immunodiffusion-kits-and-reagents">Immunodiffusion Reagents and Plates</a>
                          </li>
                          <li>
                            <a href="complement-fixation-antigens-controls">Complement Fixation Reagents</a>
                          </li>
                        </ul>
                      </div>
                      <div className="col-sm-4">
                        <ul className="multi-column-dropdown">
                          <li>
                            <a className="dropdown-item-text ">
                              <b>Product Labeling</b>
                            </a>
                          </li>
                          <li className="dropdown-divider"></li>
                          <li>
                            <a
                              target="_blank"
                              href="/package_inserts/italian_labeling/italian_labeling.pdf"
                            >
                              <i>Italian Environmental Labeling</i>{' '}
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="col-sm-4">
                        <ul className="multi-column-dropdown">
                          <li>
                            <a className="dropdown-item-text ">
                              <b>PCR</b>
                            </a>
                          </li>
                          <li className="dropdown-divider"></li>
                          <li>
                            <a href="candida-species"><i>Candida</i> species</a>
                          </li>
                          <li>
                            <a href="candida-auris"><i>Candida auris</i></a>
                          </li>
                          <li>
                            <a href="aspergillus"><i>Aspergillus</i></a>
                          </li>
                          <li>
                            <a href="pneumocystis-jirovecii"><i>Pneumocystis jirovecii</i></a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </ul>
                </li>
                {/* <!--                    <li className="nav-item active">-->
<!--                        <a className="nav-link" href="/diseases" style="color:#002F87; !important;">DISEASES</a>-->
<!--                    </li>--> */}
                <li className="nav-item active">
                  <a className="nav-link" href="/about" style={{ color: '#002F87' }}>
                    ABOUT
                  </a>
                </li>
                <li className="nav-item active">
                  <a className="nav-link" href="/careers" style={{ color: '#002F87' }}>
                    CAREERS
                  </a>
                </li>
                <li className="nav-item active">
                  {/* <a
                    className='nav-link'
                    href=''
                    style={{ color: '#002F87' }}
                    onClick={(e) => {
                      e.preventDefault();
                      setShow(1);
                    }}
                  >*/}
                  <a className='nav-link' href='/contact' style={{ color: '#002F87' }}>
                    CONTACT
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>


    </div>
  );
}

export default Nav;
