import React from 'react';
import Table from 'react-bootstrap/Table';

function Agm_Relevancy() {
  return (
    <div className='d-flex flex-column p-0'>
      <div className='col-12 text-center pb-4'>
        <h1 className='verlag-bold'>
          Clinical Relevancy<sup>1</sup>
        </h1>
      </div>
      <div className='col-12  col-sm-12 offset-md-2 col-md-8 offset-xl-2 col-xl-8 d-flex flex-column flex-lg-row justify-content-between mb-3'>
        <Table
          className='table table-info table-bordered mb-2 mb-lg-0 me-0 me-lg-2 verlag-black text-center align-left w-auto border-top-0 pb-3'
          style={{ float: 'left', borderLeftColor: 'white', borderTopColor: 'white' }}
        >
          <tbody>
            <tr className='border-top-0'>
              <td
                colSpan='2'
                rowSpan='2'
                className='h4 align-middle table_header border-top-0'
                style={{
                  backgroundColor: 'white',
                  borderTopColor: 'white',
                  borderLeftColor: 'white',
                  verticalAlign: 'middle',
                }}
              >
                BAL <br /> Fluid
              </td>
              <td colSpan='2' className='h5 align-middle border-top-0'>
                EORTC
              </td>
            </tr>
            <tr className='border-top-0'>
              <td className='h6 align-middle'>Proven/Probable</td>
              <td className='h6 align-middle'>No IA</td>
            </tr>
            <tr>
              <td rowSpan='2' className='h7' style={{ verticalAlign: 'middle' }}>
                IMMY clarus Asp. GM EIA
              </td>
              <td className='h6 align-middle'>Pos.</td>
              <td className='h6 align-middle'>32</td>
              <td className='h6 align-middle'>0</td>
            </tr>
            <tr>
              <td className='h6 align-middle'>Neg.</td>
              <td className='h6 align-middle'>6</td>
              <td className='h6 align-middle'>216</td>
            </tr>
          </tbody>
        </Table>
        <Table
          className='table table-info table-bordered pb-0 mb-0 verlag-black  text-center  align-left w-auto border-top-0'
          style={{ float: 'left', borderLeftColor: 'white', borderTopColor: 'white' }}
        >
          <tbody>
            <tr className='border-top-0'>
              <td
                className='h4 align-middle table_header border-top-0'
                style={{
                  backgroundColor: 'white',
                  borderTopColor: 'white !important',
                  borderRightColor: 'white',
                  borderLeftColor: 'white',
                }}
              >
                BAL
                <br /> Fluid
              </td>
              <td className='h5 align-middle border-top-0'>Calculated</td>
              <td className='h5 align-middle border-top-0'>95% CI</td>
            </tr>
            <tr>
              <td className='h5 align-middle'>Sensitivity</td>
              <td className='h6 align-middle'>84.2%</td>
              <td className='h6 align-middle'>69.6%-92.6%</td>
            </tr>
            <tr>
              <td className='h5 align-middle'>Specificity</td>
              <td className='h6 align-middle'>100.0%</td>
              <td className='h6 align-middle'>98.3%-100%</td>
            </tr>
          </tbody>
        </Table>
      </div>

      <div className='col-12 col-sm-12 offset-md-2 col-md-8 offset-xl-2 col-xl-8 d-flex flex-column flex-lg-row justify-content-between'>
        <Table
          className='table table-info table-bordered mb-2 mb-lg-0 me-0 me-lg-2 verlag-black  text-center  align-left w-auto border-top-0 pb-3'
          style={{ float: 'left', borderLeftColor: 'white', borderTopColor: 'white' }}
        >
          <tbody>
            <tr className='border-top-0'>
              <td
                colSpan='2'
                rowSpan='2'
                className='h4 align-middle table_header border-top-0'
                style={{
                  backgroundColor: 'white',
                  borderTopColor: 'white',
                  borderLeftColor: 'white',
                  verticalAlign: 'middle',
                }}
              >
                BAL <br /> Fluid
              </td>
              <td colSpan='2' className='h5 align-middle border-top-0'>
                EORTC
              </td>
            </tr>
            <tr className='border-top-0'>
              <td className='h6 align-middle'>Proven/Probable</td>
              <td className='h6 align-middle'>No IA</td>
            </tr>
            <tr>
              <td rowSpan='2' className='h7' style={{ verticalAlign: 'middle' }}>
                IMMY clarus Asp. GM EIA
              </td>
              <td className='h6 align-middle'>Pos.</td>
              <td className='h6 align-middle'>14</td>
              <td className='h6 align-middle'>1</td>
            </tr>
            <tr>
              <td className='h6 align-middle'>Neg.</td>
              <td className='h6 align-middle'>2</td>
              <td className='h6 align-middle'>19</td>
            </tr>
          </tbody>
        </Table>
        <Table
          className='table table-info table-bordered pb-0 mb-0 verlag-black  text-center  align-left w-auto border-top-0'
          style={{ float: 'left', borderLeftColor: 'white', borderTopColor: 'white' }}
        >
          <tbody>
            <tr className='border-top-0'>
              <td
                className='h4 align-middle table_header border-top-0'
                style={{
                  backgroundColor: 'white',
                  borderTopColor: 'white !important',
                  borderRightColor: 'white',
                  borderLeftColor: 'white',
                }}
              >
                BAL
                <br /> Fluid
              </td>
              <td className='h5 align-middle border-top-0'>Calculated</td>
              <td className='h5 align-middle border-top-0'>95% CI</td>
            </tr>
            <tr>
              <td className='h5 align-middle'>Sensitivity</td>
              <td className='h6 align-middle'>87.5%</td>
              <td className='h6 align-middle'>64.0%-96.5%</td>
            </tr>
            <tr>
              <td className='h5 align-middle'>Specificity</td>
              <td className='h6 align-middle'>95.0%</td>
              <td className='h6 align-middle'>76.4%-99.1%</td>
            </tr>
          </tbody>
        </Table>
      </div>
      <small className='mx-auto'>1. IMMY AGM101 IFU</small>
    </div>
  );
}

export default Agm_Relevancy;
