/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import DocumentTable from './DocumentTable';
import axios from 'axios';
import Asp_Relevancy from './Asp_Relevancy';
import CragSQ_Relevancy from './CragSQ_Relevancy';
import Crag_Relevancy from './Crag_Relevancy';
import CTA_Relevancy from './CTA_Relevancy';
import Cry_Relevancy from './Cry_Relevancy';
import Cab_Relevancy from './Cab_Relevancy';
import Bta_Relevancy from './Bta_Relevancy';
import Agm_Relevancy from './Agm_Relevancy';
import Hgm_Relevancy from './Hgm_Relevancy';
import * as DOMPurify from 'dompurify';
import ContactModal from './ContactModal';

function Product(props) {
  let [docs, setDocs] = useState();
  let [show, setShow] = useState();

  useEffect(() => {

    setShow(props.show)

    axios
      .post('/etqdocs', {
        id: props.etq,
        headers: {
          'Content-Type': ' text/html',
        },
      })
      .then((response) => {
       // console.log("36: " +response.data)
        let data = response.data.sort();
       // console.log(JSON.stringify(data))
        data.sort((a, b) => a.title.localeCompare(b.title));
        setDocs(data);
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  return (
    <div>
      <ContactModal show={show} setNavShowFalse={() => {setShow(0)}}/>
      <div className='container-fluid  pt-3'>
        <div className='container'>
          {/* <!-- Portfolio Item Heading --> */}
          <div className='row'>
            {props.title_override ? (
              props.prod_title
            ) : (
              <div className='col-12 offset-sm-2 col-sm-10 offset-md-1 col-md-10 offset-lg-3 col-lg-8'>
                <h1
                  className='verlag-compressed-bold  my-0'
                  style={{ color: 'grey', marginBottom: '-5px' }}
                >
                  {' '}
                  <u>{props.title_1}</u>
                </h1>
                <h1
                  className='verlag-black my-0 display-4 text-capitalize font-italic '
                  style={{ color: props.title_2_color, marginTop: '-5px' }}
                >
                  {props.title_2}
                </h1>
                <h1
                  className='verlag-light_italic display-4 my-0 text-responsive-small '
                  style={{ marginTop: '-10px', color: 'grey' }}
                >
                  {props.title_3}
                </h1>
              </div>
            )}
          </div>

          <hr />

          <div className='row'>
            <div className='col-sm-12 col-md-12  col-lg-8'>
              <img className='img-fluid' src={props.product_image} alt='' />
            </div>

            <div className='col-sm-12 col-md-12 offset-lg-0 col-lg-4 verlag-light'>
              <h1 className=' verlag-light_italic  text-center my-0 mr-8'>It's About</h1>
              <h1 className='verlag-black pt-0 text-center display-4 font-italic'>TIME</h1>
              {props.product_desc}
              <div className='text-center verlag-black'>
                <button
                  type='button'
                  className='btn btn-outline-info btn-block'
                  onClick={() => window.location.href ='/contact'}
                >
                  CONTACT US
                </button>
              </div>
              <h6 className='verlag-black text-right pt-2 pt-sm-2 pt-lg-4 pt-md-4 pt-xl-4'>
                {props.CE_marked ? (
                  <img
                    className='img-fluid'
                    style={{ maxWidth: '35px' }}
                    src='/images/ce-mark.png'
                  />
                ) : (
                  <></>
                )}
                {props.CE_desc}
              </h6>
            </div>
          </div>
        </div>
      </div>
      {!props.noImages}
      <div className='container-fluid  pb-3 pt-4'>
        <div className='container'>
          <div className='row '>
            <div className='col-12 col-sm-4 text-center  pt-3'>
              {props.image_1}
              <h5 className='pt-3'>{props.image_1_desc1}</h5>
              <h4 className='verlag-black'> {props.image_1_desc2}</h4>
            </div>
            <div className='col-12 col-sm-4 text-center pt-3 '>
              {props.image_2}
              <h5 className='pt-3'>{props.image_2_desc1}</h5>
              <h4 className='verlag-black'>{props.image_2_desc2}</h4>
            </div>
            <div className='col-12 col-sm-4 text-center pt-3'>
              {props.image_3}
              <h5 className='pt-3'>{props.image_3_desc1}</h5>
              <h4 className='verlag-black'>{props.image_3_desc2}</h4>
            </div>
          </div>
        </div>
      </div>
      <hr style={{ width: '75%' }} className='mx-auto' />
      {props.relevancy ? (
        <div className='container-fluid pt-3'>
          <div className='container'>
            <div className='row'>
              {/* Add Product Relevancy here */}
              {props.relevancy === 'Asp_Relevancy' ? (
                <Asp_Relevancy />
              ) : props.relevancy === 'Crag_Relevancy' ? (
                <Crag_Relevancy />
              ) : props.relevancy === 'CragSQ_Relevancy' ? (
                <CragSQ_Relevancy />
              ) : props.relevancy === 'CTA_Relevancy' ? (
                <CTA_Relevancy />
              ) : props.relevancy === 'Cry_Relevancy' ? (
                <Cry_Relevancy />
              ) : props.relevancy === 'Cab_Relevancy' ? (
                <Cab_Relevancy />
              ) : props.relevancy === 'Bta_Relevancy' ? (
                <Bta_Relevancy />
              ) : props.relevancy === 'Agm_Relevancy' ? (
                <Agm_Relevancy />
              ) : props.relevancy === 'Hgm_Relevancy' ? (
                <Hgm_Relevancy />
              ) : (
                <></>
              )}
            </div>
          </div>
          <hr style={{ width: '75%' }} className='mx-auto' />
        </div>
      ) : (
        <></>
      )}

      <div className='container mt-5 mb-5'>
        <div className='row'>
          <div className='col-12  col-sm-12 offset-md-1 col-md-10 offset-lg-2 col-lg-8'>
            <Tabs
              className='nav nav-tabs verlag-black border-bottom'
              defaultActiveKey={props.studies ? 'studies' : 'docs'}
              fill
            >
              {props.studies ? (
                <Tab className='border' eventKey='studies' title='STUDIES'>
                  <div
                    className='tab-pane active table-responsive'
                    id='STUDIES'
                    style={{ minHeight: '250px' }}
                  >
                    <table className='table table-responsive' width='100%'>
                      <thead style={{ height: '0px' }}>
                        <tr style={{ height: '0px', border: 'none' }}>
                          <th
                            scope='col'
                            width='100%'
                            style={{ height: '0px', border: 'none' }}
                            className='m-0 p-0'
                          ></th>
                        </tr>
                      </thead>
                      <tbody style={{ color: '#01579B' }}>
                        {props.studies?.map((study) => {
                          return (
                            <tr key={study.title}>
                              <th scope='row'>
                                <a
                                  target='_blank'
                                  rel='noreferrer'
                                  dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(
                                      study.title +
                                        '<br/>' +
                                        '<small>' +
                                        study.citation +
                                        '</small>',
                                    ),
                                  }}
                                  href={study.link}
                                ></a>
                              </th>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </Tab>
              ) : (
                <></>
              )}
              <Tab className='border' eventKey='docs' title='DOCUMENTS'>
                <div className='tab-pane' id='DOCUMENTS' style={{ minHeight: '250px' }}>
                  {docs ? <DocumentTable documents={docs} /> : <></>}
                </div>
              </Tab>
              <Tab className='border' eventKey='ordering' title='ORDERING INFORMATION'>
                <div
                  className='tab-pane table-responsive'
                  id='ordering'
                  style={{ minHeight: '250px' }}
                >
                  <table className='table table-borderless' width='100%'>
                    <thead style={{ height: '0px' }}>
                      <tr style={{ border: 'none', width: '100%' }}>
                        <th
                          scope='col'
                          style={{ height: '0px', border: 'none', width: '33%' }}
                        ></th>
                        <th
                          scope='col'
                          style={{ height: '0px', border: 'none', width: '33%' }}
                        ></th>
                        <th
                          scope='col'
                          style={{ height: '0px', border: 'none', width: '33%' }}
                        ></th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.ordering.map((line) => {
                        return (
                          <tr key={line.name}>
                            <th
                              scope='row'
                              className='orderingTable'
                              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(line.name) }}
                            ></th>
                            <td
                              className='text-center orderingTable'
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(line.quantity),
                              }}
                            ></td>
                            <td
                              className='text-right orderingTable'
                              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(line.code) }}
                            ></td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </Tab>
            </Tabs>

            <div className='tab-content border'></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Product;
