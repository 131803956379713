import React from 'react';

function Bta_Relevancy() {
  return (
    <div>
      <div className='col-12 text-center pb-4'>
        <h1 className='verlag-bold'>
          Clinical Relevancy
          <sup>
            <small>1</small>
          </sup>
        </h1>
      </div>
      <div className='col-12  col-sm-12 offset-md-2 col-md-8 offset-xl-2 col-xl-8'>
        <table
          className='table table-striped table-bordered table-info pb-0 mb-2 my-auto verlag-black  text-center  align-middle w-auto mx-auto border-top-0'
          style={{ borderLeftColor: 'white !important', borderTopColor: 'white !important' }}
        >
          <thead>
            <tr className='border-top-0'>
              <th
                scope='col'
                className='h2 align-middle table_header'
                style={{
                  backgroundColor: 'white !important',
                  borderTopColor: 'white !important',
                  borderLeftColor: 'white !important',
                }}
              >
                Serum
              </th>
              <th scope='col' className='h5 align-middle'>
                Sensitivity
              </th>
              <th scope='col' className='h5 align-middle'>
                Specificity
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope='row' className='h5 align-middle'>
                CF/ID
              </th>
              <td className='h4 align-middle'>100%</td>
              <td className='h4 align-middle'>99%</td>
            </tr>
          </tbody>
        </table>
        <small className='text-left'>
          1)Rollins LO, et al. Evaluation of the IMMY OMEGA Enzyme Immunoassay for the Detection of
          Blastomyces Antibodies. Mayo Clinic Poster Presented at ASM 2006.{' '}
        </small>
      </div>
    </div>
  );
}

export default Bta_Relevancy;
