import React from 'react';

function Asp_Relevancy() {
  return (
    <div>
      <div className='col-12 text-center pb-4'>
        <h1 className='verlag-bold'>
          Clinical Relevancy
          <sup>
            <small>2,3</small>
          </sup>
        </h1>
      </div>
      <div className='col-12 verlag-light pt-2 text-center'>
        <h3 className='verlag-bold'>Asp LFA Publication Findings</h3>
        <p>
          The clinical performance of the IMMY sona <i>Aspergillus</i> Lateral Flow Assay in serum
          samples from cases classified as proven/probable/chronic IA/IFD (n=32) using EORTC/MSG
          criteria and control patients with no evidence of IFD (n=100).
        </p>
      </div>
      <div className='col-12 text-center '>
        <table
          className='table table-striped table-bordered table-info pb-0 mb-0 mx-auto verlag-black text-center w-auto align-middle overflow-auto'
          style={{ borderLeftColor: 'white', borderTopColor: 'white' }}
        >
          <thead>
            <tr>
              <th scope='col' className='h5 align-middle' width='25%'>
                Sensitivity
              </th>
              <th scope='col' className='h5 align-middle' width='25%'>
                Specificity{' '}
              </th>
              <th scope='col' className='h5 align-middle' width='25%'>
                PPV
              </th>
              <th scope='col' className='h5 align-middle' width='25%'>
                NPV
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='h4 align-middle'>97%</td>
              <td className='h4 align-middle'>98%</td>
              <td className='h4 align-middle'>94%</td>
              <td className='h4 align-middle'>99%</td>
            </tr>
          </tbody>
        </table>
        <small className='text-left'>
          2) Lewis W., et al. An evaluation of the performance of the IMMY sōna Aspergillus GM LFA
          when testing serum to aid in the diagnosis of IA. J Clin Micro. 2020; 00053-20.{' '}
        </small>

        <p className='text-left verlag-light pt-2'>
          In serum samples, the AGM LFA will correctly generate a positive result for <b>97%</b> of
          patients with IA, and a negative result for <b>98%</b> of patients without IA. The PPV
          shows that among patients with a positive AGM LFA result, the probability that they truly
          have IA is <b>94%</b>. NPV shows that among patients with a negative AGM LFA result, the
          probability that they do not have IA is <b>99%</b>. <sup>2</sup>{' '}
        </p>
        <hr width='50%' className='mx-auto my-3' />
      </div>

      <div className='col-12 text-center'>
        <h3 className='verlag-bold'>Asp LFA Publication Findings</h3>
        <p className='verlag-light'>
          The diagnostic performance of the IMMY sona <i>Aspergillus</i> Lateral Flow Assay in BAL
          samples from hematology patients classified as proven IPA (n=11) using EORTC/MSG criteria
          and control patients with no evidence of IPA (n=117).
        </p>
        <table
          className='table table-striped table-bordered table-info mx-auto pb-0 mb-0 w-auto verlag-black text-center align-middle '
          style={{ borderLeftColor: 'white', borderTopColor: 'white' }}
        >
          <thead>
            <tr>
              <th scope='col' className='h5 align-middle' width='25%'>
                Sensitivity
              </th>
              <th scope='col' className='h5 align-middle' width='25%'>
                Specificity{' '}
              </th>
              <th scope='col' className='h5 align-middle' width='25%'>
                NPV
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='h4 align-middle'>91%</td>
              <td className='h4 align-middle'>92%</td>
              <td className='h4 align-middle'>99%</td>
            </tr>
          </tbody>
        </table>
        <small className='text-left'>
          3) Mercier T, et al. Lateral flow assays for diagnosing invasive pulmonary aspergillosis
          in adult hematology patients: A comparative multicenter study. Med Mycol. 2019.
        </small>
        <p className='verlag-light pt-2'>
          In BAL samples, the AGM LFA will correctly generate a positive result for 91% of patients
          with IA, and a negative result for <b>92%</b> of patients without IA. The NPV shows that
          among patients with a negative AGM LFA result, the probability that they do not have IA is{' '}
          <b>99%</b>. <sup>3</sup>{' '}
        </p>
      </div>
      <div className='col-12 text-center verlag-bold'>
        <h1>Procedure</h1>
      </div>
      <div className='col-12  col-sm-12 offset-md-1 col-md-10 text-center embed-responsive embed-responsive-16by9'>
        <iframe
          className='embed-responsive-item'
          src='https://www.youtube.com/embed/e0BxLuv1EdQ'
          frameBorder='0'
          allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
          allowfullscreen
        ></iframe>
      </div>
    </div>
  );
}

export default Asp_Relevancy;
