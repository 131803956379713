import React from 'react';

function Hgm_Relevancy() {
  return (
    <div>
      <div className='col-12 text-center pb-4'>
        <h1 className='verlag-bold'>
          Clinical Relevancy<sup></sup>
        </h1>
      </div>

      <div className='col-12  col-sm-12 offset-md-1 col-md-10 offset-lg-0 col-lg-12 pt-3'>
        <div className='d-flex justify-content-md-around justify-content-center flex-column flex-sm-row align-items-center'>
          <div>
            <table
              className='table table-striped table-bordered table-success pb-0 mb-2 mb-sm-0 my-auto verlag-black text-center align-middle w-auto table-responsive'
              style={{
                borderLeftColor: 'white !important',
                borderTopColor: 'white !important',
                borderRightColor: 'white !important',
                maxWidth: '395px',
              }}
            >
              <thead>
                <tr>
                  <th
                    scope='col'
                    className='h5 align-middle'
                    colSpan='2'
                    style={{
                      backgroundColor: 'white !important',
                      borderTopColor: 'white !important',
                      borderLeftColor: 'white !important',
                      borderRightColor: 'white !important',
                    }}
                  >
                    <p>Quantitative Standard Curve Method</p>
                    <p>vs.</p>
                    <p>
                      Culture-Proven Histoplasmosis & Controls<sup>1</sup>
                    </p>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope='col' className='h5 align-middle' width='50%'>
                    Sensitivity
                  </th>
                  <th scope='col' className='h5 align-middle' width='50%'>
                    Specificity
                  </th>
                </tr>
                <tr>
                  <td className='h4 align-middle'>98%</td>
                  <td className='h4 align-middle'>97%</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <table
              className='table table-striped table-bordered table-success pb-0 mb-0 my-auto verlag-black text-center align-middle w-auto table-responsive'
              style={{
                borderLeftColor: 'white !important',
                borderTopColor: 'white !important',
                borderRightColor: 'white !important',
                maxWidth: '395px',
              }}
            >
              <thead>
                <tr>
                  <th
                    scope='col'
                    className='h5 align-middle'
                    colSpan='2'
                    style={{
                      backgroundColor: 'white !important',
                      borderTopColor: 'white !important',
                      borderLeftColor: 'white !important',
                      borderRightColor: 'white !important',
                    }}
                  >
                    <p>Semi-Quantitative Calibrator Cut-off Method</p>
                    <p>vs.</p>
                    <p>
                      Culture-Proven Histoplasmosis & Controls<sup>1</sup>
                    </p>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope='col' className='h5 align-middle' width='50%'>
                    Sensitivity
                  </th>
                  <th scope='col' className='h5 align-middle' width='50%'>
                    Specificity
                  </th>
                </tr>
                <tr>
                  <td className='h4 align-middle'>95%</td>
                  <td className='h4 align-middle'>98%</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <br />
        <small className='text-left'>
          1) Cáceres DH, Samayoa BE, Medina NG, et al. Multicenter Validation of Commercial
          Antigenuria Reagents To Diagnose Progressive Disseminated Histoplasmosis in People Living
          with HIV/AIDS in Two Latin American Countries. J Clin Microbiol. 2018;56(6):e01959-17.
        </small>
      </div>
    </div>
  );
}

export default Hgm_Relevancy;
