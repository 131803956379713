/* eslint-disable react/prop-types */
import React from 'react';

function DocumentTable(props) {
  // function downloadDoc(id, name) {
  //   //console.log("HTTING DOC TABLE AXIOS REQ")
  //   axios
  //     .get(
  //       '/downloadDoc',
  //       { params: { id: id, name: name } },
  //       {
  //         responseType: 'arraybuffer',
  //       },
  //     )
  //     .then((response) => {
  //       //console.log(response.data)
  //       const url = window.URL.createObjectURL(
  //         new Blob([response.data], { type: 'application/pdf' }),
  //       );
  //       var link = document.createElement('a');
  //       link.href = url;
  //       link.setAttribute('download', name);
  //       document.body.appendChild(link);
  //       link.click();
  //       // window.open(response.data)
  //       // const blob = new Blob([response.data], { type: 'application/pdf' })
  //       // saveAs(blob, name)
  //     })
  //     .catch((e) => {
  //       console.error(e);
  //     });
  // }

  return (
    <table className='table table-responsive' width='100%'>
      <thead style={{ height: '0px' }}>
        <tr style={{ height: '0px', border: 'none' }}>
          <th
            scope='col'
            width='100%'
            style={{ height: '0px', border: 'none' }}
            className='m-0 p-0'
          ></th>
        </tr>
      </thead>
      <tbody style={{ color: '#01579B' }}>
        {props.documents?.map((doc) => {
         // console.log("48:" + JSON.stringify(doc))
          return (
            <tr key={doc.title}>
              <th scope='row'>
                <a
                  href={`/downloadDoc?id=${doc.id}&name=${doc.title}`}
                  target='_blank'
                  rel='noreferrer'
                >
                  {doc.title}
                </a>
              </th>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default DocumentTable;
