/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import React, {useState, useRef, useEffect} from 'react'
import Modal from 'react-bootstrap/Modal';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import Swal from 'sweetalert2';


  // const [SuccessMsg, setSuccessMsg] = useState('');
  // const [ErrorMsg, setErrorMsg] = useState('');
  // const [valid_token, setValidToken] = useState([]);

  
  

function ContactModal(props) {

  const SITE_KEY = process.env.REACT_APP_reCAPTCHA_SITE_KEY;
  const SECRET_KEY = process.env.REACT_APP_reCAPTCHA_SECRET_KEY;

  const captchaRef = useRef(null);


  let [show, setShow] = useState();

  useEffect(() => {
    setShow(props.show)
  })

  const verifyToken = async (token) => {
    let APIResponse = [];

    try {
      let response = await axios.post(`/verify-token`, {
        reCAPTCHA_TOKEN: token,
        Secret_Key: SECRET_KEY,
      });

      APIResponse.push(response['data']);
      return APIResponse;
    } catch (error) {
      console.log(error);
    }
  };

  const sendEmail = async (e) => {
    //console.log(e.target[0].value)
    let token = captchaRef.current.getValue();
    captchaRef.current.reset();

    if (token) {
      let valid_token = await verifyToken(token);
      //setValidToken(valid_token);

      console.log(valid_token, 'TOKEN');
      if (valid_token[0].success === true) {
        //console.log('verified');
        let data = {};
        //Preps Form Data for /email route
        e.map(function (elem) {
          data[elem.name] = elem.value;
        });
        //console.log(data, 'DATA');
        data['token'] = token;
        axios
          .post('/email', {
            data,
          })
          .then((response) => {
            //console.log(response)
            //setDocs(response.data)
            response.data.success ? 
            Swal.fire({
              title: 'Sent!',
              text: 'Message sent. We will be in touch!',
              icon: 'success',
            }) : 
            Swal.fire({
              title: 'Uh Oh!',
              text: "Your message didn't go through! Please try again!",
              icon: 'error',
            })
          })
          .catch((e) => {
            console.error(e);
          });
        console.log(token);
      } else {
        console.log('not verified');
        //setErrorMsg(' Sorry!! Verify you are not a bot');
      }
    }
  };

  return (
    <Modal show={show}>
        <Modal.Header className='modal-header'>
          <h5 className='modal-title' id='exampleModalLabel'>
            Contact IMMY
          </h5>
          <button type='button' className='close' onClick={() => {setShow(0); props.setNavShowFalse()}}>
            <span aria-hidden='true'>&times;</span>
          </button>
        </Modal.Header>
        <form
          id='emailForm'
          onSubmit={(event) => {
            event.preventDefault();
            console.log(event.target.elements, 'FORM');
            sendEmail(Array.from(event.target.elements));
          }}
        >
          <Modal.Body className='modal-body'>
            <p>
              We are offering Covid-19 Testing <a href='https://portal.immylabs.com'>HERE</a>.
            </p>
            {/* <!--<p> <h5 className="modal-title" id="exampleModalLabel">To contact IMMY, please send emails to:</h5></p> */}
            {/* <p><h3><a href="mailto:contact@immy.com">contact@immy.com</a></h3></p>
                <p>Please provide the following information:</p>
                <p><ul><li>Name</li>
                <li>Email</li>
                <li>Country</li>
                <li>State</li>
                <li>City</li>
                <li>Organization</li>
                <li>Phone</li>
                <li>Product Interested In</li>
            </ul></p>--> */}
            <div className='form-group'>
              <label htmlFor='name' className='col-form-label'>
                Name:
              </label>
              <input name='name' type='text' className='form-control' />
            </div>
            <div className='form-group'>
              <label htmlFor='email' className='col-form-label'>
                Email:
              </label>
              <input name='email' type='email' className='form-control' id='email' />
            </div>
            <div className='form-group'>
              <label htmlFor='country' className='col-form-label'>
                Country:
              </label>
              <input name='country' type='text' className='form-control' id='org' />
            </div>
            <div className='form-group'>
              <label htmlFor='state' className='col-form-label'>
                State:
              </label>
              <input name='state' type='text' className='form-control' id='org' />
            </div>
            <div className='form-group'>
              <label htmlFor='city' className='col-form-label'>
                City:
              </label>
              <input name='city' type='text' className='form-control' id='org' />
            </div>
            <div className='form-group'>
              <label htmlFor='organization' className='col-form-label'>
                Organization:
              </label>
              <input name='organization' type='text' className='form-control' id='org' />
            </div>
            <div className='form-group'>
              <label htmlFor='title' className='col-form-label'>
                Title:
              </label>
              <input name='title' type='text' className='form-control' id='org' />
            </div>
            <div className='form-group'>
              <label htmlFor='phone' className='col-form-label'>
                Phone:
              </label>
              <input name='phone' type='text' className='form-control' id='org' />
            </div>
            <div className='form-group'>
              <label htmlFor='product' className='col-form-label'>
                Product Interested In:
              </label>
              <input name='product' type='text' className='form-control' />
            </div>
            <div className='form-group'>
              <label htmlFor='message-text' className='col-form-label'>
                Message:
              </label>
              <textarea name='message' className='form-control' id='message-text'></textarea>
            </div>
            <div className='verlag-light'>
              <p>Call us: (405) 360-4669 8:30am - 5:00pm CST</p>
              <p>
                For IMMYLabs questions related to our COVID-19 testing, please email{' '}
                <a href='mailto:questions@immylabs.com'>questions@immylabs.com</a>
              </p>
            </div>
            <ReCAPTCHA className='recaptcha' sitekey={SITE_KEY} ref={captchaRef} />
          </Modal.Body>
          <Modal.Footer className='modal-footer'>
            <button type='button' className='btn btn-secondary' onClick={() => {setShow(0); props.setNavShowFalse()}} data-dismiss='modal'>
              Close
            </button>
            <button type='submit' className='btn btn-primary'>
              Send message
            </button>
          </Modal.Footer>
        </form>
      </Modal>
  )
}

export default ContactModal